




/*
 *
 * Artikellistentabelle
 */

table.artikelliste_table > thead.artikelliste_table_head .artikelliste_table_head_bildspalte {
    width: 10%;
}

table.artikelliste_table > tbody.artikelliste_table_body .artikelliste_table_body_bildspalte {
    text-align: center;
    vertical-align: middle;
}

table.artikelliste_table > thead.artikelliste_table_head .artikelliste_table_head_artikelnrspalte {
    width: 20%;
    text-align: center;
}

table.artikelliste_table > tbody.artikelliste_table_body .artikelliste_table_body_artikelnummerspalte {
    text-align: center;
    vertical-align: middle;
}

table.artikelliste_table > tbody.artikelliste_table_body .artikelliste_table_body_infospalte {
    vertical-align: middle;
}

table.artikelliste_table > thead.artikelliste_table_head .artikelliste_table_head_bestandspalte {
    width: 5%;
    text-align: center;
}

table.artikelliste_table > tbody.artikelliste_table_body .artikelliste_table_body_bestandspalte {
    text-align: center;
    vertical-align: middle;
}

table.artikelliste_table > thead.artikelliste_table_head .artikelliste_table_head_preisspalte {
    width: 15%;
    text-align: center;
}

table.artikelliste_table > tbody.artikelliste_table_body .artikelliste_table_body_preisspalte {
    text-align: center;
    vertical-align: middle;
}

table.artikelliste_table > tbody.artikelliste_table_body .artikelliste_table_body_aktionsspalte {
    width: 15%;
    text-align: center;
    vertical-align: middle;
}

.artikelliste_liste_bildcontainer {
    position: relative;
    width: 100px;
    height: 100px;
    border: 1px solid #333;
    border-radius: 4px;
}

.artikelliste_liste_bildcontainer > img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    border-radius: 4px;
}

div.artikelliste_wk_button > span > i {
    float: right;
    color: var(--weiss);
    cursor: pointer;
}

div.artikelliste_wk_button > span {
    background: transparent;
}

.wk_button_icon_container > i {
    padding-top: 25px;
    color: var(--weiss);
    font-size: 40px;
}

.preis > p {
    font-weight: bold;
}

div.artikelliste_kachel_container {
    position: relative;
    min-height: 320px;
    margin-bottom: 20px;
}

div.artikelliste_kachel {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: fit-content;
    border: 1px solid #e6e6e6;
}

div.artikelliste_kachel:hover {
    z-index: 3;
    box-shadow: 0 0 25px #333;
    background: #eee;
    height: fit-content;
}

div.artikelliste_kachel > div.artikelliste_kachel_infocontainer > table.table {
    border-top: 1px solid black;
}

div.artikelliste_kachel:hover {
    border: 1px solid var(--weiss);
}

div.artikelliste_kachel > div.artikelliste_kachel_infocontainer > table.table {
    margin-bottom: 0;
}

div.artikelliste_kachel > div.artikelliste_kachel_infocontainer > table.table > tbody > tr.artikelliste_kachel_table_zeile_artikel {
    height: 60px;
}

div.artikelliste_kachel > div.artikelliste_kachel_infocontainer > table.table > tbody > tr.artikelliste_kachel_table_zeile_info {
    height: 0;
}

div.artikelliste_kachel > div.artikelliste_kachel_infocontainer > table.table > tbody > tr.artikelliste_kachel_table_zeile_info > td > div {
    font-size: 14px;
}

div.artikelliste_kachel > div.artikelliste_kachel_infocontainer > table.table > tbody > tr.artikelliste_kachel_table_zeile_artikel > td.artikelliste_kachel_table_bez {
    width: 90%;
    font-weight: 300;
}

div.artikelliste_kachel > div.artikelliste_kachel_infocontainer > table.table > tbody > tr.artikelliste_kachel_table_zeile_artikel > td.artikelliste_kachel_table_bestand {
    width: 10%;
}

div.artikelliste_kachel > div.artikelliste_kachel_infocontainer > table.table > tbody > tr.artikelliste_kachel_table_zeile_preis {
    width: 100%;
    height: 90px;
    font-weight: 300;
    text-align: right;
}

div.artikelliste_kachel > div.artikelliste_kachel_infocontainer > table.table > tbody > tr.artikelliste_kachel_table_zeile_preis > td {
    vertical-align: bottom;
}

div.artikelliste_kachel:hover > table.table > tbody > tr.artikelliste_kachel_table_zeile_preis > td > div > p {
    color: var(--weiss);
}

.artikelliste_kachel_table_zeile_preis a {
    color: var(--weiss);
    font-weight: bold;
    font-size: 18px;
    text-decoration: underline;
}

div.artikelliste_kachel:hover > table.table > tbody > tr > td.artikelliste_kachel_table_bez {
    color: var(--weiss);
}

div.artikelliste_kachel tr.artikelliste_kacel_table_zeile_aktion {
    display: none;
}

div.artikelliste_kachel:hover tr.artikelliste_kacel_table_zeile_aktion {
    display: table-row;
}

div.artikelliste_kachel:hover .artikelliste_kachel_details_overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: .8;
    background-color: #ccc;
}

div.artikelliste_kachel .artikelliste_kachel_details {
    display: none;
}

div.artikelliste_kachel:hover .artikelliste_kachel_details {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    padding: 10%;
    font-weight: bold;
    text-align: center;
}

.artikelliste_kachel_bildcontainer {
    position: relative;
    padding-top: 75%;
}

.artikelliste_kachel_bildcontainer > img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
}

.artikelliste_me_pe.input-group > .input-group-addon {
    height: 34px;
    background: transparent;
}

.artikelliste_me_pe.input-group > .input-group-addon:first-child {
    border-right: 1px solid #ccc;
}

.artikelliste_me_pe.input-group > .input-group-btn > .btn {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}
.kachel {
    border: 1px solid var(--gelb);
    border-radius: 6px;
}
.kachel__info {
    padding: 15px;
    background-color: var(--gelb-5);
    border-radius: 0 0 6px 6px;
}
.kachel__info-bez,
.kachel__info-bestand,
.kachel__info-aktion {
    color: var(--schwarz);
    margin-bottom: 15px;
}
.kachel__info-bestand span {
    font-weight: 400;
}
.kachel__info-aktion button.btn.btn-primary.btn-block:hover {
    background-color: var(--gelb);
    box-shadow: 2px 2px 0px var(--green);
}
.kachel__info-aktion button.btn.btn-primary.btn-block:hover i {
    color: var(--weiss);
}
.list__alternative .list__element-top {
    border: 1px solid var(--grau-20);
    border-radius: 6px 6px 0 6px;
}
.list__alternative .element__item-preis {
    display: flex;
    margin-bottom: 15px;
}
.list__alternative .element__item-preis span,
.list__alternative .element__item-preis p {
    font-size: 16px;
    color: var(--blau);
}
.list__alternative .artikelliste_liste_bildcontainer {
    position: relative;
    width: 100%;
    height: 100%;
    border: none;
}
.list__alternative .element__item-artnum {
    display: flex;
    font-weight: 600;
    margin-bottom: 15px;
}
.list__alternative .element__item-input input.form-control {
    width: 30%;
    margin-top: 15px;
}