



/*
 *
 * Main
 */

.searchform {
    margin-bottom: 25px;
}

.searchform > .input-group > .input-group-addon {
    color: var(--weiss);
    background-color: transparent;
}

.nav-stacked .sidemenu_head {
    min-height: 30px;
    padding: 5px;
}

.nav-stacked .sidemenu_row {
    min-height: 30px;
    border-top: 1px solid #333;
}

.nav-stacked > li.active > a,
.nav-stacked > li.active:hover > a {
    color: var(--weiss);
    background: #fff;
}

.nav-stacked .sidemenu_row > a > span {
    margin-top: 6px;
}

.nav-stacked .sidemenu_row > a {
    padding: 12px 5px 9px 5px;
}

.nav-stacked .sidemenu_row > a:hover {
    color: var(--weiss);
    background-color: #fff;
}

.sidemenu_ug_row {
    min-height: 30px;
    margin-left: -10px;
    margin-right: -1px;
    padding-top: 5px;
    padding-left: 20px;
    list-style: none;
    text-align: left;
}

.nav > li > a:hover {
    background-color: #fff;
}

.sidemenu_ug_row > a:hover {
    color: var(--weiss);
    background: #fff;
}

li.sidemenu_ug_row:hover {
    color: var(--weiss);
    background: #fff;
}

.sidemenu_ug_row.active > a {
    color: var(--weiss);
    background: #fff;
}

#content .breadcrumb {
    padding: 0;
    background-color: #fff;
}

.wk_container {
    margin-top: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.autocomplete {
    position: relative;
    width: 100%;
}

.autocomplete ul {
    position: absolute;
    left: 0;
    top: 34px;
    z-index: 1000;
    width: 100%;
    padding-left: 0;
    border-left: 1px solid #888;
    border-right: 1px solid #888;
    border-bottom: 1px solid #888;
}

.autocomplete li {
    width: 100%;
    padding: .4em 1em;
    text-align: left;
    list-style: none;
    background-color: #fff;
}

.autocomplete li.active {
    width: 100%;
    background-color: #4bf;
}

.autocomplete .highlight {
    background-color: #e2e2e2;
}

.autocomplete li.active .highlight {
    color: #fff;
    background: #666;
}
div.head__img:hover img.img-bar_finder {
    -webkit-animation-name: spin;
    -webkit-animation-duration: 12000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 12000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 12000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;

    animation-name: spin;
    animation-duration: 12000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}





@-ms-keyframes spin {
    from { -ms-transform: rotate(0deg); }
    to { -ms-transform: rotate(360deg); }
}
@-moz-keyframes spin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

/*
*
* Extra Small (XS)
*/

@media (max-width: 767px) {
    .head__menu-li a.dropdown-toggle {
        height: 100%;
        display: flex;
        align-items: center;
    }
	.head__menu-main span {
        display: none;
    }
}


/*
*
* Small (SM)
*/

@media (min-width: 768px) and (max-width: 991px) {
    .head__menu-main span {
        display: none;
    }
}


/*
*
* Medium (MD)
*/

@media (min-width: 992px) and (max-width: 1199px) {

}


/*
*
* Large (LG)
*/

@media (min-width: 1200px) {

}

