@charset "utf-8";

/* Variablen */
:root {
    --blau: #004494;
    --blau-7: #F4F4FB;
    --gelb: #F49E00;
    --gelb-7: #FEF8EC;
    --gelb-5: #FEFAF2;
    --green: #87BC48;
    --grau: #4D4D4D;
    --grau-90: #1B1B1B;
    --grau-80: #333;
    --grau-20: #CDCDCD;
    --schwarz: #000;
    --weiss: #FFF;
}

/* open-sans-regular - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/open-sans-v34-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/open-sans-v34-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/open-sans-v34-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/open-sans-v34-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/open-sans-v34-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/open-sans-v34-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/roboto-v30-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/roboto-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/roboto-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/roboto-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/roboto-v30-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-500 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('../fonts/roboto-v30-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/roboto-v30-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/roboto-v30-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/roboto-v30-latin-500.woff') format('woff'), /* Modern Browsers */
    url('../fonts/roboto-v30-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/roboto-v30-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-700 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/roboto-v30-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/roboto-v30-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/roboto-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/roboto-v30-latin-700.woff') format('woff'), /* Modern Browsers */
    url('../fonts/roboto-v30-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/roboto-v30-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-900 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('../fonts/roboto-v30-latin-900.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/roboto-v30-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/roboto-v30-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/roboto-v30-latin-900.woff') format('woff'), /* Modern Browsers */
    url('../fonts/roboto-v30-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/roboto-v30-latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
}