


/*
 *
 * Konto
 */

.konto_xs_accordion {
    margin-top: 20px;
}

.konto_xs_accordion > div > div > .panel-default {
    border: none;
    box-shadow: none;
}

.konto_xs_accordion > div > div > .panel-default > .panel-heading {
    background-color: #fff;
    border: none;
}

.kontosuche > .input-group > div.input-group-addon {
    color: var(--weiss);
    background-color: transparent;
}
.konto__ul.nav-stacked > li > a,
.konto__ul.nav-stacked > li.active > a,
.konto__ul.nav-stacked > li > a:hover,
.konto__ul.nav-stacked > li.active:hover > a {
    color: var(--weiss);
    background-color: var(--gelb);
    border: 1px solid var(--gelb);
}
/*.konto__ul.nav-stacked > li > a:hover,
.konto__ul.nav-stacked > li.active:hover > a {
    color: var(--blau);
    background-color: var(--gelb);
}*/
konto__ul.nav-stacked .konto__li > a {
    padding: 12px 5px 9px 5px;
}
.konto__ul .konto__li {
border: none;
}
.konto__li-ul-nav li.sidemenu_ug_row {
    background-color: var(--gelb-5);
    margin-left: 0;
}
.konto__li-ul-nav li.sidemenu_ug_row a {
    color: var(--blau)
}
.konto__kopf-sm input.form-control,
.konto__kopf-sm select.form-control{
    border: 1px solid var(--gelb);
    box-shadow: none;
}
.konto__kopf-sm .kontosuche div.input-group-addon {
    background-color: var(--gelb);
    color: var(--weiss);
}
.belegliste__kopf .down-up i {
    font-size: 24px;
    font-weight: bold;
    color: var(--green);
    display: flex;
    justify-content: center;
}
.belegliste__artikel button span {
    color: var(--green);
}
.belegliste__artikel button:hover span {
    color: var(--weiss);
}
.konto .panel-default {
    border: 1px solid var(--gelb);
}

.belegliste__artikel {
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 2px solid var(--gelb);
}
.belegliste__artikel:last-child {
    border-bottom: none;
}
/*
*
* Extra Small (XS)
*/

@media (max-width: 767px) {
    .belegliste__kopf p {
        font-size: 10px;
    }
    .belegliste__artikel-img {
        display: flex;
        justify-content: center;
    }
    .konto .konto__sm a {
        color: var(--blau);
    }
    .konto .konto__sm a:hover {
        color: var(--weiss);
    }
    .belegliste__artikel input.form-control {
        border: 1px solid var(--gelb);
        box-shadow: none;
    }

}


/*
*
* Small (SM)
*/

@media (min-width: 768px) and (max-width: 991px) {

}


/*
*
* Medium (MD)
*/

@media (min-width: 992px) and (max-width: 1199px) {

}


/*
*
* Large (LG)
*/

@media (min-width: 1200px) {

}