


/*
 *
 * Warenkorb Preview
 */

.warenkorbvoransicht_kopf {
    height: 40px;
    padding: 10px;
    background-color: var(--weiss);
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    text-align: center;
}

.warenkorbvoransicht_kopf a {
    color: white;
    font-weight: bold;
}

.warenkorbvoransicht_zeile {
    padding: 10px 0 10px 0;
    font-size: 12px;
}

.warenkorbvoransicht_zeile a:hover {
    opacity: .5;
}

.warenkorbvoransicht_preis {
    padding: 0 5px 5px 5px;
    text-align: center;
}

.warenkorbvoransicht_preis > a > span {
    color: #000;
    font-size: 15px;
    font-weight: bold;
}