/*
 *
 * Allgemein
 */

html {
    color: var(--grau);
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
}

body {
    color: var(--grau);
    background-color: var(--weiss);
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
}

a,
a *,
button,
button *,
.btn,
.btn *,
.cursor-pointer,
.cursor-pointer *,
*[href],
*[href] *,
*[onclick],
*[onclick] *,
*[ondblclick],
*[ondblclick] *,
*[ui-sref],
*[ui-sref] *,
*[ng-click],
*[ng-click] * {
    cursor: pointer !important;
}

.btn.xxx---is-active,
.btn.xxx---is-active * {
    cursor: default !important;
}

.table > tbody > tr > td {
    border: none;
}

.table.table-bordered > tbody > tr > td {
    border: 1px solid #ddd;
}

input.form-control {
    box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.25);
}

.xxx--product--availability---g {
    color: #0f0;
}

.xxx--product--availability---y {
    color: #ff7f00;
}

.xxx--product--availability---r {
    color: #f00;
}
.fa-times {
    color: var(--blau);
}
/*.blanket__formular input.form-control,
.blanket__formular select.form-control,
.blanket__formular textarea {
    border: 1px solid var(--gelb);
    box-shadow: none;
}*/
input.form-control,
select.form-control,
textarea.form-control {
    border: 1px solid var(--gelb);
    box-shadow: none;
}
form a {
    color: var(--green) !important;
}
.blanket__formular button.formular__btn i {
    font-size: 14px !important;
}
.blanket__formular button.formular__btn:hover i {
    color: var(--weiss);
}
.modal-header button.close {
    color: var(--green);
    opacity: 1 !important;
}

section.portal__titel h1 {
    font-size: 40px;
    text-transform: uppercase;
}
.div-btn {
    display: inline-flex;
    background-color: var(--green);
    padding: 5px;
    border-radius: 6px;
    margin-top: 15px;
}