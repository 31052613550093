





/*
 *
 * Artikelgruppen
 */

div.artikelgruppen_container {
    margin-bottom: 25px;
    border: 1px solid #e6e6e6;
    border-radius: 5px 5px 5px 5px;
}

div.artikelgruppen_container:hover {
    border: 1px solid var(--weiss);
}

.artikelgruppen_container_image {
    position: relative;
    padding-top: 75%;
    border-bottom: 1px solid #333;
}

.artikelgruppen_container_image > img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
}

.artikelgruppen_container_image .overlay {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.artikelgruppen_container:hover .artikelgruppen_container_image .overlay {
    display: block;
}

.artikelgruppen_container_image .overlay .background {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    opacity: .6;
    background: #ccc;
}

.artikelgruppen_container_image .overlay .text {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 3;
    padding: 10%;
    text-align: center;
}

.artikelgruppen_container_image .overlay .text p {
    font-weight: bold;
    font-size: 20px;
}

.artikelgruppen_container_image_layer {
    position: absolute;
    top: 0;
    width: 100%;
    height: 40px;
    margin-top: 30%;
    padding-top: 10px;
    text-align: center;
    background: none repeat scroll 0 0 black;
    /* Fallback for web browsers that don't support RGBa */
    background-color: rgb(0, 0, 0);
    /* RGBa with .6 opacity */
    background-color: rgba(0, 0, 0, .9);
    /* For IE 5.5 - 7*/
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#99000000", endColorstr="#99000000");
    /* For IE 8*/
    -ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#99000000", endColorstr="#99000000");
}

.artikelgruppen_container_image_layer span {
    color: white;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: bold;
}

div.artikelgruppen_container div.artikelgruppen_container_titel {
    height: 40px;
    padding: 10px;
    font-weight: 300;
}

div.artikelgruppen_container:hover div.artikelgruppen_container_titel {
    color: var(--weiss);
}

.artikelgruppen_container_titel > span {
    float: right;
}