




/*
 *
 * Navigation
 */

.navbar_mobile_menu {
    float: right;
    width: 40%;
    padding: 10px;
    background: #fff;
}

.navbar_mobile_menu > div,
.navbar_mobile_umenu > div {
    padding: 5px;
}

.navbar_mobile_menu > .active > a > div > p,
.navbar_mobile_umenu > .active > a > div > p {
    color: var(--weiss);
}

.navbar_mobile_menu > div > a > div > p > i,
.navbar_mobile_umenu > div > a > div > p > i {
    padding-right: 5px;
    color: var(--weiss);
    font-size: 16px;
}

.navbar_mobile_umenu {
    float: right;
    width: 40%;
    padding: 10px;
    background: #fff;
    -webkit-box-shadow: -10px 10px 15px 5px rgba(170, 170, 170, .4);
    -moz-box-shadow: -10px 10px 15px 5px rgba(170, 170, 170, .4);
    box-shadow: -10px 10px 15px 5px rgba(170, 170, 170, .4);
}

.navbar-icon > a > i {
    padding: 10px;
    color: var(--weiss);
    font-size: 25pt;
}

.navbar-logo {
    display: none !important;
}

.navbar-logo img {
    max-height: 100%;
    max-width: 100%;
}

