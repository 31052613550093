



/**
 * portal
 * @package xxx
 * @component
 */

.xxx--portal + .xxx--portal {
    margin-top: 30px;
}

.xxx--portal-mod--header {
    padding: 30px 15px;
}

.xxx--portal-mod--headline {
    color: #666;
    font-size: 26px;
}

*[class*="xxx--portal-mod"].headline-centered .xxx--portal-mod--header {
    text-align: center;
}

*[class*="xxx--portal-mod"].headline-centered .xxx--portal-mod--headline {
    color: #c93a3a;
}

.xxx--portal-mod--block {
    position: relative;
    display: block;
    /*padding-top: 15px;
    padding-bottom: 15px;*/
    overflow: hidden;
}

/*.xxx--portal-mod--block + .xxx--portal-mod--block {
    margin-top: 50px;
}*/

.xxx--portal-mod--block--header {
    margin-bottom: 15px;
}

.xxx--portal-mod--block--headline {
    margin: 0;
    font-size: 24px;
}

.xxx--portal-mod--block--headline * {
    font-size: 16px;
    color: #c93a3a;
}

.xxx--portal-mod--block--teaser-text a {
    color: #c93a3a;
    text-decoration: underline;
}

.xxx--portal-mod--block-news--article {
    text-align: left;
}

.xxx--portal-mod--block-news--image-link {
    display: block;
    border: thin solid #c93a3a;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .7);
    overflow: hidden;
}

.xxx--portal-mod--block-link {
    position: relative;
    display: block;
    min-height: 60px;
    margin: 0 15px;
    overflow: hidden;
    border: thin solid #fff;
    box-shadow: 0 0 3px rgba(0, 0, 0, .3);
    transition: box-shadow 300ms ease;
}

.xxx--portal-mod--block-link:hover {
    box-shadow: 0 0 6px rgba(0, 0, 0, .6);
}

.xxx--portal-mod--block-link--label {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    padding: 15px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #0a71b4;
    background: rgba(255, 255, 255, .9);
    transition: padding 300ms ease;
}

.xxx--portal-mod--block-link:hover .xxx--portal-mod--block-link--label.xxx---has-src {
    padding: 30px 15px;
}

.xxx--portal-mod--block-link--image-background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.kontakt__footer div.galbild_vorsch  {
    height: auto !important;
}
.kontakt__footer div.galbild_vorsch img {
    position: relative;
    margin: auto;
    border-radius: 4px;
    width: 100%;
}

/* XXS */
@media (max-width: 359px) {
    .xxx--portal-mod--block .xxx--list-item {
        width: 100%;
    }
}

/* XS */
@media (max-width: 767px) {
    .xxx--portal-mod--block--header {
        margin-top: 30px;
    }

    .xxx--portal-mod--block--headline,
    .xxx--portal-mod--block--teaser-text {
        text-align: center;
    }
}


/**
 * collapse
 * @package xxx
 * @component
 */

.xxx--collapse {
    max-height: 1000px;
    overflow: hidden !important;
    transition: max-height 500ms ease, padding-top 500ms ease, margin-bottom 500ms ease, opacity 500ms ease, border 500ms ease, background 500ms ease, border 500ms ease !important;
}

.xxx--collapse.xxx---is-collapsed {
    max-height: 0 !important;
}

.xxx--collapse.xxx---is-expanded {
    max-height: 3000px;
    overflow: visible !important;
}


/**
 * scroll-sticky
 * @package xxx
 * @component
 */

.xxx--scroll-sticky {
    position: fixed !important;
    top: 0;
    z-index: 100000;
    width: 100%;
    background: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, .5);
}

.xxx--scroll-sticky .navbar-logo {
    display: block !important;
    height: 45px;
}

.xxx--scroll-sticky .xxx--page-header--img,
.xxx--scroll-sticky .head__img-drucktuch,
.xxx--scroll-sticky .head__img,
.xxx--scroll-sticky .head__logo,
.xxx--scroll-sticky .head__lang,
.xxx--scroll-sticky .navbar-collapse .navbar-nav .navbar-logo {
    display: none;
}
.xxx--scroll-sticky .navbar {
    margin-bottom: 0;
}


/**
 * parallax
 * @package xxx
 * @component
 */

*[xxx--parallax] {
    position: absolute;
    transition: top 300ms ease;
}


/**
 * pagination
 * @package xxx
 * @component
 */

.xxx--pagination {
    font-family: 'Open Sans', sans-serif;
}


/**
 * v-card
 * @package xxx
 * @component
 */

.xxx--v-card {
    background: #fff;
    border: thin solid #ccc;
    border-radius: 5px;
}

.xxx--v-card--info {
    margin-top: 15px;
}

.xxx--v-card--image {
    max-width: 100%;
    padding: 15px;
    background: #ccc;
}

*[class*="xxx--v-card--"][class*="-text"],
*[class*="xxx--v-card--"][class*="-address-"] {
    font-size: 12px;
}


/**
 *
 * @package xxx
 * @modifier
 */

.xxx---shadow {
    box-shadow: 0 0 6px rgba(0, 0, 0, .4);
}

.xxx---relative {
    position: relative;
}

.xxx---absolute {
    position: absolute;
}

@media (max-width: 767px) {
    .xxx-xs---absolute {
        position: absolute;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .xxx-sm---absolute {
        position: absolute;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .xxx-md---absolute {
        position: absolute;
    }
}

@media (min-width: 1200px) {
    .xxx-lg---absolute {
        position: absolute;
    }
}

*[class*="xxx---"][class*="---absolute"].xxx---t-0 {
    top: 0;
}

*[class*="xxx---"][class*="---absolute"].xxx---t-1 {
    top: 5px;
}

*[class*="xxx---"][class*="---absolute"].xxx---t-2 {
    top: 10px;
}

*[class*="xxx---"][class*="---absolute"].xxx---t-3 {
    top: 15px;
}

*[class*="xxx---"][class*="---absolute"].xxx---t-4 {
    top: 30px;
}

*[class*="xxx---"][class*="---absolute"].xxx---t-5 {
    top: 45px;
}

*[class*="xxx---"][class*="---absolute"].xxx---l-0 {
    left: 0;
}

*[class*="xxx---"][class*="---absolute"].xxx---l-1 {
    left: 5px;
}

*[class*="xxx---"][class*="---absolute"].xxx---l-2 {
    left: 10px;
}

*[class*="xxx---"][class*="---absolute"].xxx---l-3 {
    left: 15px;
}

*[class*="xxx---"][class*="---absolute"].xxx---l-4 {
    left: 30px;
}

*[class*="xxx---"][class*="---absolute"].xxx---l-5 {
    left: 45px;
}

*[class*="xxx---"][class*="---absolute"].xxx---r-0 {
    right: 0;
}

*[class*="xxx---"][class*="---absolute"].xxx---r-1 {
    right: 5px;
}

*[class*="xxx---"][class*="---absolute"].xxx---r-2 {
    right: 10px;
}

*[class*="xxx---"][class*="---absolute"].xxx---r-3 {
    right: 15px;
}

*[class*="xxx---"][class*="---absolute"].xxx---r-4 {
    right: 30px;
}

*[class*="xxx---"][class*="---absolute"].xxx---r-5 {
    right: 45px;
}

*[class*="xxx---"][class*="---absolute"].xxx---b-0 {
    bottom: 0;
}

*[class*="xxx---"][class*="---absolute"].xxx---b-1 {
    bottom: 5px;
}

*[class*="xxx---"][class*="---absolute"].xxx---b-2 {
    bottom: 10px;
}

*[class*="xxx---"][class*="---absolute"].xxx---b-3 {
    bottom: 15px;
}

*[class*="xxx---"][class*="---absolute"].xxx---b-4 {
    bottom: 30px;
}

*[class*="xxx---"][class*="---absolute"].xxx---b-5 {
    bottom: 45px;
}

*[class*="xxx---"][class*="---absolute"].xxx---b-3 {
    bottom: 15px;
}

.xxx---absolute-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.xxx---z-10 {
    z-index: 10;
}

.xxx---z-100 {
    z-index: 100;
}

.xxx---z-1000 {
    z-index: 1000;
}

.xxx---z-10000 {
    z-index: 10000;
}

.xxx---z-100000 {
    z-index: 100000;
}

.xxx---overflow-hidden {
    overflow: hidden;
}

.xxx---block,
.xxx---block-inside > * {
    display: block !important;
}

.xxx---inline,
.xxx---inline-inside > * {
    display: inline-block !important;
}

@media (max-width: 767px) {
    .xxx-xs---inline-inside > * {
        display: inline-block !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .xxx-sm---inline-inside > * {
        display: inline-block !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .xxx-md---inline-inside > * {
        display: inline-block !important;
    }
}

@media (min-width: 1200px) {
    .xxx-lg---inline-inside > * {
        display: inline-block !important;
    }
}

.xxx---vertical-top,
.xxx---inline-inside.xxx---vertical-top > *,
.xxx-xs---inline-inside.xxx---vertical-top > *,
.xxx-sm---inline-inside.xxx---vertical-top > *,
.xxx-md---inline-inside.xxx---vertical-top > *,
.xxx-lg---inline-inside.xxx---vertical-top > * {
    vertical-align: top;
}

.xxx---table-cell {
    display: table-cell !important;
}

.xxx---none {
    display: none !important;
}

@media (max-width: 767px) {
    .xxx-xs---none {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .xxx-sm---none {
        display: none !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .xxx-md---none {
        display: none !important;
    }
}

@media (min-width: 1200px) {
    .xxx-lg---none {
        display: none !important;
    }
}

.xxx---is-open {
    display: block;
}

.xxx---cursor-default {
    cursor: default !important;
}

.xxx---cursor-pointer {
    cursor: pointer !important;
}

.xxx---center {
    margin-top: 50% !important;
    margin-left: 50% !important;
    transform: translate(-50%, -50%) !important;
}

.xxx---center-y {
    margin-top: 50% !important;
    transform: translateY(-50%) !important;
}

.xxx---center-x {
    margin-left: 50% !important;
    transform: translateX(-50%) !important;
}

.xxx---square {
    position: relative;
    display: block;
    width: 100%;
}

.xxx---square:after {
    display: block;
    padding-bottom: 100%;
    content: '';
}

.xxx--square--outer {
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
}

.xxx--square--inner {
    position: absolute;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    height: 100%;
    width: 100%;
    margin: auto;
    padding: 15px;
    background: #fff;
    text-align: center;
}

.xxx--square--image {
    max-width: 100%;
    max-height: 100%;
    margin-top: 50%;
    transform: translateY(-50%);
}

.xxx---square-background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.xxx---square-background-colored {
    /*background-color: #ccc;*/
    /*outline: thin solid #ccc;*/
}

.xxx---clear {
    clear: both !important;
}

.xxx---opacity {
    opacity: 1 !important;
}

.xxx---opacity-half,
.xxx---opacity-5 {
    opacity: .5 !important;
}

.xxx---rounded {
    border-radius: 3px;
}

.xxx---w-25 {
    width: 25% !important;
}

.xxx---w-33 {
    width: 33.3% !important;
}

.xxx---w-50 {
    width: 50% !important;
}

.xxx---w-66 {
    width: 66.6% !important;
}

.xxx---w-75 {
    width: 75% !important;
}

.xxx---full-width,
.xxx---w-100 {
    width: 100% !important;
}

@media (max-width: 767px) {
    .xxx-xs---w-25 {
        width: 25% !important;
    }

    .xxx-xs---w-33 {
        width: 33.3% !important;
    }

    .xxx-xs---w-50 {
        width: 50% !important;
    }

    .xxx-xs---w-66 {
        width: 66.6% !important;
    }

    .xxx-xs---w-75 {
        width: 75% !important;
    }

    .xxx-xs---w-100 {
        width: 100% !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .xxx-sm---w-25 {
        width: 25% !important;
    }

    .xxx-sm---w-33 {
        width: 33.3% !important;
    }

    .xxx-sm---w-50 {
        width: 50% !important;
    }

    .xxx-sm---w-66 {
        width: 66.6% !important;
    }

    .xxx-sm---w-75 {
        width: 75% !important;
    }

    .xxx-sm---w-100 {
        width: 100% !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .xxx-md---w-25 {
        width: 25% !important;
    }

    .xxx-md---w-33 {
        width: 33.3% !important;
    }

    .xxx-md---w-50 {
        width: 50% !important;
    }

    .xxx-md---w-66 {
        width: 66.6% !important;
    }

    .xxx-md---w-75 {
        width: 75% !important;
    }

    .xxx-md---w-100 {
        width: 100% !important;
    }
}

@media (min-width: 1200px) {
    .xxx-lg---w-25 {
        width: 25% !important;
    }

    .xxx-lg---w-33 {
        width: 33.3% !important;
    }

    .xxx-lg---w-50 {
        width: 50% !important;
    }

    .xxx-lg---w-66 {
        width: 66.6% !important;
    }

    .xxx-lg---w-75 {
        width: 75% !important;
    }

    .xxx-lg---w-100 {
        width: 100% !important;
    }
}

.xxx---no-margin,
.xxx---m-0 {
    margin: 0 !important;
}

.xxx---m-1 {
    margin: 5px !important;
}

.xxx---m-2 {
    margin: 10px !important;
}

.xxx---m-3 {
    margin: 15px !important;
}

.xxx---m-4 {
    margin: 30px !important;
}

.xxx---m-5 {
    margin: 45px !important;
}

.xxx---mt-0 {
    margin-top: 0 !important;
}

.xxx---mt-1 {
    margin-top: 5px !important;
}

.xxx---mt-2 {
    margin-top: 10px !important;
}

.xxx---mt-3 {
    margin-top: 15px !important;
}

.xxx---mt-4 {
    margin-top: 30px !important;
}

.xxx---mt-5 {
    margin-top: 45px !important;
}

.xxx---mt-6 {
    margin-top: 60px !important;
}

.xxx---mb-0 {
    margin-bottom: 0 !important;
}

.xxx---mb-1 {
    margin-bottom: 5px !important;
}

.xxx---mb-2 {
    margin-bottom: 10px !important;
}

.xxx---mb-3 {
    margin-bottom: 15px !important;
}

.xxx---mb-4 {
    margin-bottom: 30px !important;
}

.xxx---mb-5 {
    margin-bottom: 45px !important;
}

.xxx---mtb-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.xxx---mtb-1 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.xxx---mtb-2 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.xxx---mtb-3 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.xxx---mtb-4 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}

.xxx---mtb-5 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
}

.xxx---ml-0 {
    margin-left: 0 !important;
}

.xxx---ml-1 {
    margin-left: 5px !important;
}

.xxx---ml-2 {
    margin-left: 10px !important;
}

.xxx---ml-3 {
    margin-left: 15px !important;
}

.xxx---ml-4 {
    margin-left: 30px !important;
}

.xxx---ml-5 {
    margin-left: 45px !important;
}

.xxx---mr-0 {
    margin-right: 0 !important;
}

.xxx---mr-1 {
    margin-right: 5px !important;
}

.xxx---mr-2 {
    margin-right: 10px !important;
}

.xxx---mr-3 {
    margin-right: 15px !important;
}

.xxx---mr-4 {
    margin-right: 30px !important;
}

.xxx---mr-5 {
    margin-right: 45px !important;
}

.xxx---mlr-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.xxx---mlr-1 {
    margin-left: 5px !important;
    margin-right: 5px !important;
}

.xxx---mlr-2 {
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.xxx---mlr-3 {
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.xxx---mlr-4 {
    margin-left: 30px !important;
    margin-right: 30px !important;
}

.xxx---mlr-5 {
    margin-left: 45px !important;
    margin-right: 45px !important;
}

@media (max-width: 767px) {
    .xxx-xs---mt-3 {
        margin-top: 15px !important;
    }

    .xxx-xs---mlr-0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .xxx-xs---mlr-3 {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .xxx-sm---mt-3 {
        margin-top: 15px !important;
    }

    .xxx-sm---mlr-0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .xxx-sm---mlr-3 {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .xxx-md---mt-3 {
        margin-top: 15px !important;
    }

    .xxx-md---mlr-0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .xxx-md---mlr-3 {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }
}

@media (min-width: 1200px) {
    .xxx-lg---mt-3 {
        margin-top: 15px !important;
    }

    .xxx-lg---mlr-0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .xxx-lg---mlr-3 {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }
}

.xxx---no-padding,
.xxx---p-0 {
    padding: 0 !important;
}

.xxx---p-1 {
    padding: 5px !important;
}

.xxx---p-2 {
    padding: 10px !important;
}

.xxx---p-3 {
    padding: 15px !important;
}

.xxx---p-4 {
    padding: 30px !important;
}

.xxx---p-5 {
    padding: 45px !important;
}

.xxx---pt-zero,
.xxx---pt-0 {
    padding-top: 0 !important;
}

.xxx---pt-1 {
    padding-top: 5px !important;
}

.xxx---pt-2 {
    padding-top: 10px !important;
}

.xxx---pt-3 {
    padding-top: 15px !important;
}

.xxx---pt-4 {
    padding-top: 30px !important;
}

.xxx---pt-5 {
    padding-top: 45px !important;
}

.xxx---pb-zero,
.xxx---pb-0 {
    padding-bottom: 0 !important;
}

.xxx---pb-1 {
    padding-bottom: 5px !important;
}

.xxx---pb-2 {
    padding-bottom: 10px !important;
}

.xxx---pb-3 {
    padding-bottom: 15px !important;
}

.xxx---pb-4 {
    padding-bottom: 30px !important;
}

.xxx---pb-5 {
    padding-bottom: 45px !important;
}

.xxx---ptb-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.xxx---ptb-1 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.xxx---ptb-2 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.xxx---ptb-3 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.xxx---ptb-4 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

.xxx---ptb-5 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
}

.xxx---pl-0 {
    padding-left: 0 !important;
}

.xxx---pl-1 {
    padding-left: 5px !important;
}

.xxx---pl-2 {
    padding-left: 10px !important;
}

.xxx---pl-3 {
    padding-left: 15px !important;
}

.xxx---pl-4 {
    padding-left: 30px !important;
}

.xxx---pl-5 {
    padding-left: 45px !important;
}

.xxx---pr-0 {
    padding-right: 0 !important;
}

.xxx---pr-1 {
    padding-right: 5px !important;
}

.xxx---pr-2 {
    padding-right: 10px !important;
}

.xxx---pr-3 {
    padding-right: 15px !important;
}

.xxx---pr-4 {
    padding-right: 30px !important;
}

.xxx---pr-5 {
    padding-right: 45px !important;
}

.xxx---plr-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.xxx---plr-1 {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.xxx---plr-2 {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.xxx---plr-3 {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.xxx---plr-4 {
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.xxx---plr-5 {
    padding-left: 45px !important;
    padding-right: 45px !important;
}

.xxx---cookiebot {
    display: flex;
    cursor: pointer;
}

.xxx---cookiebot-videodummy {
    width: 100%;
    height: 40vw;
    max-height: 500px;
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: 50% 50%;
    border: 1px solid #545454;
    filter: blur(5px);
    position: relative;
    opacity: 0.5;
    -ms-filter: alpha(opacity=25);
}

.xxx---cookiebot-playbutton {
    position: absolute;
    bottom: 45%;
    left: 45%;
}

.xxx---cookiebot-playbutton .fa {
    font-size: 6vw;
    margin: auto;
    z-index: 500;
}

.xxx---cookiebot-text {
    position: absolute;
    left: 10%;
    right: 10%;
    bottom: 20px;
}
