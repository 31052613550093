






/* RESULT > FILTER */
.xxx--elastic-search-result--filter {
    margin-bottom: 25px;
}

.xxx--elastic-search-result--filter--block {
    position: relative;
}

.xxx--elastic-search-result--filter--block-head {
    color: #fff;
    background: var(--gelb);
    border-radius: 6px 6px 0px 0px;
}
.xxx--elastic-search-result--filter--block-head strong {
    font-weight: 500;
    font-size: 18px;
}
.xxx--elastic-search-result--filter--block-head-title {
    display: block;
    padding: 8px 50px 7px 15px;
}

.xxx--elastic-search-result--filter--block-head-caret {
    position: absolute;
    top: 25px;
    right: 50px;
    font-size: 20px;
    color: var(--weiss);
}

.xxx--elastic-search-result--filter--block-head-reset {
    position: absolute;
    top: 22px;
    right: 15px;
    margin: 0 5px;
    padding: 5px;
    font-size: 16px;
    color: var(--weiss);
}

.xxx--elastic-search-result--filter--block-body {
    display: none;
    width: 100%;
    /*margin: 0 15px;*/
    background: var(--gelb-7);
    border-radius: 0;
    /*box-shadow: 0 5px 10px rgba(0, 0, 0, .3);*/
    border: thin solid var(--weiss);
}

.xxx--elastic-search-result--filter--block-body---is-open {
    display: block;
}

.xxx--elastic-search-result--filter--block-body---is-absolute {
    position: absolute;
    top: 40px;
    left: 0;
}
.xxx--elastic-search-result--filter--block-body li {
    border-bottom: 2px solid var(--weiss);
    padding: 5px 10px;
}
.xxx--elastic-search-result--filter--block-body li label {
    margin: 0;
}

.xxx--elastic-search-result--filter--block-body li label span,
.xxx--elastic-search-result--filter--block-body li label i {
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    color: var(--grau-90);
}
.xxx--elastic-search-result--filter--block-body .xxx--list {
    /*margin: 5px 0;*/
}

.xxx--elastic-search-result--filter--option {
    display: block;
    /*margin: 5px 0;*/
}

.xxx--elastic-search-result--filter--option-label {
    position: relative;
    width: 100%;
}

.xxx--elastic-search-result--filter--option-icon,
.xxx--elastic-search-result--filter--option-value {
    color: #666;
}

.xxx--elastic-search-result--filter--option-label:hover .xxx--elastic-search-result--filter--option-icon,
.xxx--elastic-search-result--filter--option-label:hover .xxx--elastic-search-result--filter--option-value,
.xxx--elastic-search-result--filter--option---is-active .xxx--elastic-search-result--filter--option-icon,
.xxx--elastic-search-result--filter--option---is-active .xxx--elastic-search-result--filter--option-value {
    color: #000;
}

.xxx--elastic-search-result--filter--option-count {
    position: absolute;
    top: 4px;
    right: 15px;
    color: #999;
    font-weight: lighter;
    font-size: 10px;
}

.filter__block {
    padding-left: 0;
    padding-right: 0;
}
.filter__current {
    background-color: var(--blau-7);
}

a.text-danger {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
}
a.text-danger i {
    color: var(--green);
    font-size: 18px;
}
a.text-danger span {
    color: var(--blau);
}
/* RESULT > OBJECT */
.xxx--elastic-search-result--object--image-container {
    padding-top: 15px;
    padding-bottom: 15px;
}

.xxx--elastic-search-result--object *[class*="xxx--product--"][class*="-label"] {
    display: inline-block;
    font-size: 16px;
    color: var(--grau-90);
}

.xxx--elastic-search-result--object .xxx--product--sku {
    display: block;
    margin-top: 15px;
}

.xxx--elastic-search-result--object .xxx--product--name {
    margin-top: 15px;
    margin-bottom: 0;
}

.xxx--elastic-search-result--object .xxx--product--additional {
    margin: 5px 0;
}

.xxx--elastic-search-result--object .xxx--product--additional-label {
    font-size: 10px !important;
}

.xxx--elastic-search-result--object .xxx--product--detail-list {
    margin-bottom: 0;
}

.xxx--elastic-search-result--object .xxx--product--detail-list-item--term,
.xxx--elastic-search-result--object .xxx--product--detail-list-item--data {
    display: inline-block;
    font-size: 10px !important;
}

.xxx--elastic-search-result--object .xxx--product--detail-list-item--term::after {
    content: ': ';
}

.xxx--elastic-search-result--object .xxx--product--detail-list-item--data {
    margin-bottom: 5px;
}

/* RESULT > BTN */
.xxx--elastic-search-result--btn-action {
    /*margin-top: 15px;*/
}

.xxx--elastic-search-result--btn-action > * {
    color: #fff;
}

/* RESULT > AVAILABILITY */
.xxx--elastic-search-result--availability {
    display: block;
    padding: 7px 15px;
}

.xxx--elastic-search-result--availability-icon {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: thin solid #fff;
    background: grey;
}

.xxx--elastic-search-result--availability-danger .xxx--elastic-search-result--availability-icon {
    background: red;
}

.xxx--elastic-search-result--availability-warning .xxx--elastic-search-result--availability-icon {
    background: yellow;
}

.xxx--elastic-search-result--availability-success .xxx--elastic-search-result--availability-icon {
    background: green;
}

/* RESULT > TABLE */
.xxx--elastic-search-result--table {
    margin-bottom: 0 !important;
}

.xxx--elastic-search-result--table-wrapper {
    width: 100%;
    overflow-x: auto;
}

.xxx--elastic-search-result--table-head {
    background: #ccc;
}

.xxx--elastic-search-result--table-head--cell {
    border-bottom: thin solid var(--weiss) !important;
}

.xxx--elastic-search-result--table-body--row {
    background: #fff;
}

.xxx--elastic-search-result--table-body--row:nth-of-type(2n+1) {
    background: #e9e9e9;
}

.xxx--elastic-search-result--table-body--row .xxx--product--detail-list-item--term {
    margin-right: 5px;
}

.xxx--elastic-search-result--table-body--cell-image {
    min-width: 180px;
}

.xxx--elastic-search-result--table-body--cell-image .xxx--product--image {
    margin: 0;
}

.xxx--elastic-search-result--table-body--cell-info .xxx--product--sku {
    margin-top: 0;
    margin-bottom: 10px;
}

.xxx--elastic-search-result--table-body--cell-info .xxx--product--name {
    margin-top: 0;
    margin-bottom: 5px;
}

.xxx--elastic-search-result--table-body--cell-action .xxx--elastic-search-result--btn-action {
    display: block;
}

/* RESULT > LIST */
.xxx--elastic-search-result--list .xxx--list-item {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 15px;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 0 3px rgba(0, 0, 0, .1);
    transition: box-shadow 300ms ease;
}

.xxx--elastic-search-result--list .xxx--list-item:hover {
    box-shadow: 0 0 3px rgba(0, 0, 0, .3);
}

.xxx--elastic-search-result--list .xxx--product--additional {
    margin-bottom: 30px;
}

.xxx--elastic-search-result--list .xxx--product--additional-label {
    font-size: 12px !important;
}

.xxx--elastic-search-result--list .xxx--product--detail-list {
    margin-bottom: 15px;
}

.xxx--elastic-search-result--list .xxx--product--price {
    margin-bottom: 15px;
}

.list__element {
    border-top: 1px solid var(--grau-20);
    border-right: 1px solid var(--grau-20);
    border-left: 1px solid var(--grau-20);
}
.list__element-top {
    border-radius: 6px 6px 0 0;
}
.list__element-mittel {
    background-color: var(--gelb-5);
    border-bottom: 1px solid var(--grau-20);
    border-radius: 0 0 0 6px;
}
.element__sort:first-child .element__sort-wert {
    border-radius: 0 0 0 6px;
}
.list__element-bottom {
    border-top: none;
    border-right: none;
    border-left:none;
}
.list__titel.xxx--product--name a {
    font-size: 24px;
    font-weight: 500;
    color: var(--grau-90);
}
.element__item:first-child a {
    border-radius: 6px 0 0 0;
}
.element__item-menge input.form-control,
.element__item-menge select.form-control{
    width: 80px;
    height: 40px;
    margin-right: 10px;
    border-radius: 6px !important;
    box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.25);
}
.element__item-preis p {
    font-weight: 500 !important;
    font-size: 16px !important;
}
.element__item-preis span {
    font-weight: bold;
    font-size: 32px;
    color: var(--blau);
}
.element__sort {
    flex: 1;
    border-right: 2px solid var(--weiss);
}
.element__sort:last-child {
    border-right: none;
}
.element__sort-titel {
    background-color: var(--gelb);
    padding: 5px 12px;
    font-size: 16px;
}
.element__sort-wert {
    background-color: var(--gelb-5);
    padding: 5px 12px;
    font-size: 16px;
}
.element__sort-titel p {
    color: var(--weiss);
}
.element__sort-wert p {
    color: var(--grau-90);
}
.list__element-aktion {
    display: inline-flex;
    background-color: var(--blau-7);
    padding: 10px;
    border-left: 1px solid var(--grau-20);
    border-bottom: 1px solid var(--grau-20);
    border-right: 1px solid var(--grau-20);
    border-radius: 0 0 6px 6px;
}
.list__element-aktion a span {
    color: var(--grau-90);
}
.list__element-aktion a i {
    color: var(--green);
    font-size: 20px;
}

.xxx--elastic-search-result--list .xxx--elastic-search-result--btn-action:first-child {
    margin-left: 0;
}
.aktion__div {
    display: flex;
    align-items: center;
}
.aktion__div span {
    margin-right: 6px;
}
.element__item-bestand i {
    font-size: 18px;
}
/*.xxx---square-background.xxx---square-background-scheme {
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
}*/

@media (min-width: 768px) {
    .xxx--elastic-search-result--list .xxx--elastic-search-result--btn-action {
        display: inline-block;
        margin-left: 15px;
        padding-top: 8px;
        padding-bottom: 8px;
    }
}

/* RESULT > CARD */
.xxx--elastic-search-result--card {
    margin: 0 -15px !important;
}

.xxx--elastic-search-result--card .xxx--elastic-search-result--object {
    margin-bottom: 30px !important;
}

.xxx--elastic-search-result--card-wrapper {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 3px rgba(0, 0, 0, .1);
    overflow: hidden;
    transition: box-shadow 300ms ease;
}

.xxx--elastic-search-result--card-wrapper:hover {
    box-shadow: 0 0 3px rgba(0, 0, 0, .3);
}

.xxx--elastic-search-result--card-image {
    padding: 0 !important;
    background: #ccc;
}

.xxx--elastic-search-result--card-image .xxx--product--image {
    margin: 0 !important;
    outline: 0 none !important;
}

.xxx--elastic-search-result--card-info {
    padding: 15px;
    border-top: thin solid #aaa;
}

.xxx--elastic-search-result--card-info .xxx--product--sku {
    margin-top: 0;
}

.xxx--elastic-search-result--card-info .xxx--product--name {
    min-height: 50px;
}

.xxx--elastic-search-result--card-info .xxx--product--additional {
    min-height: 50px;
}

.xxx--elastic-search-result--card-info .xxx--product--price {
    margin-top: 15px;
}

.xxx--elastic-search-result--card-action {
    border-top: thin solid #aaa;
    background: #fff;
}

/* RESULT > CONTROL */

.control__attribute select.form-control {
    box-sizing: border-box;
    height: 37px;
    border: 2px solid var(--green);
    border-radius: 6px 0px 0px 6px !important;
}
.control__direction button.control__direction-button {
    background-color: var(--green);
    border-radius: 0 6px 6px 0;
}
.control__direction button.control__direction-button i {
    color: var(--weiss);
}
.control__attribute label {
    margin-bottom: 0 !important;
}
@media (max-width: 479px) {
    .xxx--elastic-search-result--card .xxx--elastic-search-result--object {
        width: 100%;
    }
}
/*
*
* Extra Small (XS)
*/

@media (max-width: 767px) {
	.list__element.list__element-top {
        flex-direction: column;
    }
    .list__element.list__element-mittel {
        flex-wrap: wrap;
    }
    .list__element-aktion {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .list.visible-xs .xxx--elastic-search-result--btn-action-cart {
        flex-grow: 1;
    }
    .list.visible-xs .aktion__div {
        justify-content: center;
    }
    .list.visible-xs .list__element {
        border: none;
    }
    .list.visible-xs .list__element-top .element__item {
        border-bottom: 1px solid var(--grau-20);
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius:0;
    }
    .list.visible-xs .list__element-mittel {
        border-bottom: 1px solid var(--grau-20);
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: 0;
    }
    .list.visible-xs .list__element-aktion {
        border: none;
        border-radius: 0 0 6px 6px;
    }
    .element__item:first-child a {
        border-radius: 6px 6px 0 0;
    }
    .xxx---square-background {
        height: 230px;
    }
    .xxx---square-background.xxx---square-background-scheme {
        height: 190px;
    }
}


/*
*
* Small (SM)
*/

@media (min-width: 768px) and (max-width: 991px) {
    .element__item-preis span {
        font-size: 24px;
    }
}


/*
*
* Medium (MD)
*/

@media (min-width: 992px) and (max-width: 1199px) {

}


/*
*
* Large (LG)
*/

@media (min-width: 1200px) {

}