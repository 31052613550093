


/*
 *
 * Merkzettel
 */

div.merkzettel__info a h4 {
    font-size: 14px;
}

div.merkzettel__info a h4:hover {
    color: var(--weiss);
}

div.merkzettel__info .input-group span {
    color: var(--weiss);
    cursor: pointer;
}

div.merkzettel__info .input-group .input-group-addon {
    background: transparent;
}
.merkzettel__liste {
    display: flex;
    border: 1px solid var(--gelb);
    padding-bottom: 15px;
    padding-top: 15px;
    margin-bottom: 15px;
}
.merkzettel__liste:hover {
    background-color: var(--gelb-5);
    border: 2px solid var(--gelb);
    border-radius: 4px;
}
div.merkzettel__info .detail_menge {
    display: flex;
     justify-content: space-between;
    align-items: center;
}
.info__bezeichnung {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.info__bezeichnung i {
    font-size: 30px;
    color: var(--green);
}
.merkzettel__titel {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 2px solid var(--blau);
    margin-bottom: 15px;
    padding-left: 0;
    padding-right: 0;
}

.merkzettel__bild {
    display: flex;
    background-color: var(--weiss);
    border: 1px solid var(--blau);
    border-radius: 4px;
}
.merkzettel__bild img {
    margin: auto;
    border: none;
}
.merkzettel__titel h2 {
    color: var(--green);
}

.merkzettel__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.detail_menge i {
    font-size: 30px;
    color: var(--green);
}
/*
*
* Extra Small (XS)
*/

@media (max-width: 767px) {
    .merkzettel_info h2 {
        font-size: 16px;
    }
    .merkzettel__liste {
        flex-direction: column;
    }
    .info__bezeichnung h2 {
        font-size: 14px;
    }
}


/*
*
* Small (SM)
*/

@media (min-width: 768px) and (max-width: 991px) {

}


/*
*
* Medium (MD)
*/

@media (min-width: 992px) and (max-width: 1199px) {

}


/*
*
* Large (LG)
*/

@media (min-width: 1200px) {

}