


/* NAV */
.xxx--elastic-search-nav {
    margin-bottom: 15px;
}

.xxx--elastic-search-nav--title {
    padding: 7px 15px;
}

.xxx--elastic-search-nav--list {
    background: #fff;
}

.xxx--elastic-search-nav--list-item {
    position: relative;
}

.xxx--elastic-search-nav--link,
.xxx--elastic-search-nav--link-label,
.xxx--elastic-search-nav--link-count,
.xxx--elastic-search-nav--caret,
.xxx--elastic-search-nav--caret > i {
    transition: background 300ms ease, border 300ms ease, color 300ms ease, padding 300ms ease, margin 300ms ease, top 300ms ease, left 300ms ease, right 300ms ease, bottom 300ms ease;
}

.xxx--elastic-search-nav--link {
    padding: 7px 15px;
}

.xxx--elastic-search-nav--link-label {
    padding-right: 45px;
    font-size: 15px;
    color: #666;
}

.xxx--elastic-search-nav--link:hover .xxx--elastic-search-nav--link-label {
    /*padding-left: 5px;*/
    color: var(--blau);
}

.xxx--elastic-search-nav--link-count {
    position: absolute;
    top: 8px;
    right: 25px;
    color: #aaa;
    font-size: 12px;
}

.xxx--elastic-search-nav--link:hover .xxx--elastic-search-nav--link-count {
    color: #999;
}

.xxx--elastic-search-nav--caret {
    position: absolute;
    top: 0;
    right: 0;
    padding: 7px 5px;
    /*background: #ededed;
    border-left: thin solid #ccc;*/
}

.xxx--elastic-search-nav--caret > i {
    color: var(--blau);
}

/*
.xxx--elastic-search-nav--caret:hover > i,
.xxx--elastic-search-nav--link:hover + .xxx--elastic-search-nav--caret > i {
    color: #000;
}
*/

/*.xxx--elastic-search-nav--link:hover + .xxx--elastic-search-nav--caret,
.xxx--elastic-search-nav--caret:hover {
    background: #dedede;
}*/

/*.xxx--elastic-search-nav--caret:hover {
    right: -10px;
    padding-left: 10px;
    padding-right: 10px;
    border-left-color: #999;
}*/

.xxx--elastic-search-nav--link.xxx---is-active .xxx--elastic-search-nav--link-label,
.xxx--elastic-search-nav--link.xxx---is-active .xxx--elastic-search-nav--link-count,
.xxx--elastic-search-nav--caret.xxx---is-active > i {
    color: #2387fa;
}

.xxx--elastic-search-nav--list-item.xxx--list-item.xxx--list-item---level a {
    text-transform: none;
}

ul.dropdown-menu {
    min-width: 270px;
}