


/*
 *
 * Extra Small (XS)
 */

@media (max-width: 767px) {
    .warenk_table_bestellflaeche,
    .table_warenkorb_buttonleiste,
    .belegliste_kopf_preiseinheit,
    .belegliste_inhalt_preiseinheit,
    .belegliste_kopf_mengeneinheit,
    .belegliste_inhalt_mengeneinheit,
    .belegliste_kopf_bestand,
    .belegliste_inhalt_bestand,
    .belegliste_kopf_rabatt,
    .belegliste_inhalt_rabatt,
    .belegliste_kopf_pos,
    .belegliste_inhalt_pos,
    .belegliste_kopf_gesamtpreis,
    .belegliste_inhalt_gesamtpreis {
        display: none;
    }

    .navbar-header .collapse,
    .navbar-toggle {
        display: block !important;
    }

    .navbar-header {
        float: none;
    }

    .navbar-collapse.collapse {
        display: none !important;
    }

    .navbar-collapse.collapse.in {
        display: block !important;
        position: absolute;
        z-index: 10000;
        width: 100%;
        background-color: #fff;
        -webkit-box-shadow: -10px 10px 15px 5px rgba(170, 170, 170, .4);
        -moz-box-shadow: -10px 10px 15px 5px rgba(170, 170, 170, .4);
        box-shadow: -10px 10px 15px 5px rgba(170, 170, 170, .4);
    }

    .navbar_mobile_menu {
        float: right;
        width: 100%;
        background: #fff;
    }

    .navbar_mobile_menu > div,
    .navbar_mobile_umenu > div {
        padding: 5px;
    }

    .navbar_mobile_menu > .active > a > div > p,
    .navbar_mobile_umenu > .active > a > div > p {
        color: var(--weiss);
    }

    .navbar_mobile_menu > div > a > div > p > i,
    .navbar_mobile_umenu > div > a > div > p > i {
        padding-right: 5px;
        color: var(--weiss);
        font-size: 16px;
    }

    .abstand_summenfeld2 {
        padding-right: 0;
    }

    .artikeldetail_info .left {
        float: left;
    }

    .artikeldetail_info .right {
        float: right;
    }

    div.artikelliste_kachel_container {
        min-height: 400px;
    }
    section.portal__titel h1 {
        font-size: 24px;
    }
}


/*
 *
 * Small (SM)
 */

@media (min-width: 768px) and (max-width: 991px) {
    .navbar-header .collapse,
    .navbar-toggle {
        display: block !important;
    }

    .navbar-header {
        float: none;
    }

    .navbar-collapse.collapse {
        display: none !important;
    }

    .navbar-collapse.collapse.in {
        display: block !important;
        position: absolute;
        z-index: 10000;
        width: 100%;
        background-color: #fff;
        -webkit-box-shadow: -10px 10px 15px 5px rgba(170, 170, 170, .4);
        -moz-box-shadow: -10px 10px 15px 5px rgba(170, 170, 170, .4);
        box-shadow: -10px 10px 15px 5px rgba(170, 170, 170, .4);
    }

    .navbar_mobile_menu {
        float: right;
        width: 40%;
        padding: 10px;
        background: #fff;
    }

    .navbar_mobile_menu > div,
    .navbar_mobile_umenu > div {
        padding: 5px;
    }

    .navbar_mobile_menu > .active > a > div > p,
    .navbar_mobile_umenu > .active > a > div > p {
        color: var(--weiss);
    }

    .navbar_mobile_menu > div > a > div > p > i,
    .navbar_mobile_umenu > div > a > div > p > i {
        padding-right: 5px;
        color: var(--weiss);
        font-size: 16px;
    }

    .navbar_mobile_umenu {
        float: right;
        width: 40%;
        padding: 10px;
        background: #fff;
    }

    .navbar-nav > li {
        float: unset;
    }

    .navbar-nav {
        width: 100%;
    }

    .navbar-nav .open .dropdown-menu {
        float: none;
        display: block;
        position: static;
        width: auto;
        margin-top: 0;
        border: 0;
        background-color: transparent;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    div.artikelliste_kachel_container {
        min-height: 400px;
    }
}


/*
 *
 * Medium (MD)
 */

@media (min-width: 992px) and (max-width: 1199px) {
    /*.navbar-default .navbar-nav {
        margin-left: -30px;
    }*/
}


/*
 *
 * Large (LG)
 */

@media (min-width: 1200px) {
    /*.navbar-default .navbar-nav {
        margin-left: -30px;
    }*/
}



/**
 * elastic-search
 * @package xxx
 * @component
 */


