



/*
 *
 * Sonstiges (MISC)
 */

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
}

input[type=number] {
    -moz-appearance: textfield;
}

.carousel-indicators {
    display: none;
}

.italic {
    color: #ccc;
    font-style: italic;
}

img.img-thumbnail {
    width: 100%;
}

i.circle_red {
    color: #f00;
}

i.circle_yellow {
    color: #ff7f00;
}

i.circle_green {
    color: #0f0;
}

.carousel-control.left,
.carousel-control.right {
    background-image: none;
}

span.modal_close_button {
    position: absolute;
    top: 0;
    right: 0;
    margin: -20px -20px 0 0;
    padding: 2px 3px 0 4px;
    background-color: transparent;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    color: #000;
    cursor: pointer;
}

form.login_modal_form > .input-group > .input-group-addon {
    color: var(--weiss);
    background-color: var(--green);
}

.gal-thumbnail {
    background-color: transparent;
}

div.galspace {
    margin-bottom: 10px;
    padding: 0 5px 0 0;
}

div.galeriepic {
    min-height: 0;
    height: auto;
}

div.galbild_vorsch {
    position: relative;
    width: 100%;
    height: 200px;
}

div.galbild_vorsch img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    border-radius: 4px;
    cursor: pointer;
}


.portal__option div.galbild_vorsch {
    position: relative;
    width: 100%;
    height: auto;
}

.portal__option div.galbild_vorsch img {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    border-radius: 4px;
    cursor: pointer;
}
.portal.xxx--portal .xxx--portal-mod--headline,
.portal.xxx--portal h2 {
    font-size: 32px;
}
.portal.xxx--portal .xxx--portal-mod--headline {
    font-weight: 700;
}
.portal.xxx--portal  h2 {
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 20px;
}
.option-4 a   {
    color: red;
}

div.galbild_vorsch img.galerie {
    background-size: cover;
}

div.gal_list div.galerie_caption {
    height: 1em;
    max-height: 1em;
    margin: -10px 0 10px 0;
    padding: 0;
}

div.galerie_caption.gal_slide {
    margin: 20px;
}

.modal_img_container {
    position: relative;
    width: 100%;
    height: 500px;
}

.modal_img_container > img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    border-radius: 4px;
    cursor: pointer;
}

#modalimage_gal-description {
    margin-top: -18px;
}

#modalimage_gal-description span {
    padding: 5px 10px 8px 10px;
    border-radius: 5px;
    background-color: #fff;
}

label.form_elem_desc,
div.labelb {
    padding: 0 10px;
    color: #333;
    font-family: 'Open Sans', sans-serif;
    font-style: italic;
}

p.labelb {
    color: #333;
    font-family: 'Open Sans', sans-serif;
    font-style: italic;
}

input.form_elem_div.captchacode {
    width: 100px;
}

.itemnichtsichtbar {
    display: block;
    height: auto;
    border: 1px dotted #c0c0c0;
}

.itemnichtsichtbar h1,
.itemnichtsichtbar h4,
.itemnichtsichtbar h4 a,
.itemnichtsichtbar h5,
.itemnichtsichtbar p,
.itemnichtsichtbar a,
.itemnichtsichtbar div a,
.itemnichtsichtbar div label {
    color: #c0c0c0;
}

.adresse {
    margin-bottom: 15px;
}

.adresse div.galbild_vorsch {
    margin-bottom: 15px;
}

.gleicheHoehe {
    overflow: hidden;
}

.Hoehe {
    margin-bottom: -99999px;
    padding-bottom: 99999px;
}

.kachel_in_portal {
    padding-left: 0;
}

.hk-uk-img {
    float: left;
    clear: left;
    max-width: 100%;
    padding: 0 10px 10px 0;
}

.category-list,
.ranges-list,
.tags-list {
    padding-left: 10px;
    font-size: smaller;
    list-style: none;
}

.category-list li,
.ranges-list li,
.tags-list li {
    margin-left: 0;
    margin-bottom: 5px;
}

.artikelmodal_bildcontainer {
    position: relative;
    padding-top: 100%;
    width: 100%;
    border: 1px solid #333;
    border-radius: 4px;
}

.artikelmodal_bildcontainer > img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.category-list li ul {
    padding-left: 10px;
    border-left: 1px solid #ddd;
    list-style: none;
}

.category-list li a.active {
    font-weight: bold;
}

.category-list li a span {
    padding-left: 5px;
    color: gray;
    font-size: x-small;
}

.ranges-list li label {
    font-size: small;
    font-weight: normal;
}

.tags-list li label {
    padding-left: 5px;
    font-size: small;
    font-weight: normal;
}

.tags-list li input {
    margin-right: 5px;
}

.mengenformel_modal .modal-body .input-group .input-group-addon {
    min-width: 100px;
}

.mengenformel_modal .modal-body .input-group .form-control {
    max-width: 100px;
}
.modal__merkzettel input.form-control {
    border: 1px solid var(--gelb);
    box-shadow: none;
}
.modal__merkzettel .panel-default>.panel-heading {
    background-color: var(--gelb);
}
.modal__merkzettel .panel-body {
    background-color: var(--gelb-7);
}
.merkzettel__ul li a {
    color: var(--blau);
}
.modal__merkzettel .panel-body button.btn-success {
    background-color: var(--green);
    border-color: var(--green);
}
.modal__merkzettel .panel-body button.btn-success i {
    color: var(--blau);
}
.modal__merkzettel .panel-body button.btn-success:hover i {
    color: var(--weiss);
}
.modal-header > button {
    color: var(--green);
}
