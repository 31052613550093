


/*
 *
 * Cookie Warning
 */

*[ng-if="showCookieWarn"] {
    /* sticky-top */
    /* padding-top: 220px; */
    /* sticky-bottom */
    padding-bottom: 220px;
}

@media (min-width: 420px) {
    *[ng-if="showCookieWarn"] {
        /* sticky-top */
        /* padding-top: 140px; */
        /* sticky-bottom */
        padding-bottom: 140px;
    }
}

@media (min-width: 720px) {
    *[ng-if="showCookieWarn"] {
        /* sticky-top */
        /* padding-top: 60px; */
        /* sticky-bottom */
        padding-bottom: 60px;
    }
}

.cookie-warn--message {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px !important;
    font-weight: lighter;
}
.cookie-warn--message span {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px !important;
    font-weight: lighter;
}