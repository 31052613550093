




/*
 *
 * Artikellisten
 */

div.artikelliste_limit_text {
    float: right;
    color: #666;
    font-size: 14px;
    cursor: pointer;
}

div.artikelliste_limit_text > .aktiv {
    color: var(--weiss);
}

div.artikelliste_darstellung_text {
    float: right;
    margin-right: 25px;
    color: #666;
    font-size: 14px;
    cursor: pointer;
}

div.artikelliste_darstellung_text > .aktiv {
    color: var(--weiss);
}

table.table > thead.artikelliste_table_head > tr > th {
    color: #666;
}

table.table > thead.artikelliste_table_head > tr > th.aktiv {
    color: var(--weiss);
}
