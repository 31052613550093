


/*
 *
 * Schrift und Links
 */

a,
p {
    color: var(--grau-90);
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
}
span {
    font-size: 16px;
}
p {
    margin: 0;
}
a:link,
a:visited,
a:hover,
a:active,
a:focus {
    color: var(--weiss);
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    text-decoration: none;
    font-weight: 500;
}

h1,
h2,
h3,
h4,
h5 {
    color: var(--grau-90);
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}

h1 {
    font-size: 22px;
}

h2 {
    font-size: 20px;
}

h3 {
    font-size: 18px;
}

h4 {
    font-size: 16px;
}

h5 {
    font-size: 14px;
}

h5.italic {
    font-weight: 500;
}

b,
strong {
    font-weight: 600;
}

/*
*
* Extra Small (XS)
*/

@media (max-width: 767px) {
    a,
    p {
        font-size: 13px;
    }
    span {
        font-size: 13px;
    }
}


/*
*
* Small (SM)
*/

@media (min-width: 768px) and (max-width: 991px) {

}


/*
*
* Medium (MD)
*/

@media (min-width: 992px) and (max-width: 1199px) {

}


/*
*
* Large (LG)
*/

@media (min-width: 1200px) {

}