



/*
 *
 * Portal Padding
 */
.portal:nth-of-type(even),
.portal.portal__bar-finder .xxx--portal-mod:nth-of-type(even) {
    background-color: var(--blau-7);
    box-shadow: 0 2px 0 var(--blau);
}

.portal:nth-of-type(even),
.portal.portal__option .xxx--portal-mod:nth-of-type(even) {
    background-color: var(--blau-7);
    box-shadow: none;
    padding-top: 20px;
    padding-bottom: 20px;
}
.portal .portal:nth-of-type(even) {
    background-color:transparent;
}


.portal.portal__bar-finder .xxx--portal-mod {
    padding-top: 30px;
    padding-bottom: 50px;
}
.portal.portal__option .xxx--portal-mod {
    padding-top: 0;
    padding-bottom: 0;
}
.portal.portal__bar-finder .xxx--portal-mod p span {
    font-size: 18px;
    font-weight: 500;
}
.portal.portal__bar-finder .xxx--portal-mod .autocomplete input {
    border: 2px solid #87BC48;
    border-radius: 6px 6px 6px 6px;
    width: 50%;
}
.portal.portal__bar-finder .xxx--portal-mod input.form-control {
    border: 2px solid #87BC48;
    border-radius: 6px 6px 6px 6px;
    width: 100%;
    font-weight: 400;
}
.portal.portal__bar-finder .xxx--portal-mod .suggestion label {
    width: 100%;
}
.portal.portal__bar-finder .xxx--portal-mod span.input-group-addon {
    display: none;
}
.portal.portal__bar-finder .xxx--portal-mod .form input {
    border: 2px solid #87BC48;
    border-radius: 6px 0 0 6px;
    height: 42px;
}
.portal.portal__bar-finder .xxx--portal-mod .form .input-group-addon {
    background-color: var(--weiss);
    border: none;
    padding: 0;
}
.portal.portal__bar-finder .xxx--portal-mod .form select {
    border: 2px solid #87BC48;
    border-radius: 0 6px 6px 0;
    padding: 6px 50px 6px 6px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 42px;
    /*margin-left: 5px;*/
    background-image:url(../../template/images/select_2.png);
    background-position: right;
    background-repeat: no-repeat;
}
.portal.portal__bar-finder .xxx--portal-mod .form select.material {
    border-radius: 6px 6px 6px 6px;
}
.portal.portal__bar-finder .xxx--portal-mod .form .row {
    display: flex;
    margin-bottom: 30px;
}
.portal.portal__bar-finder .input-group[class*=col-] {
    padding-right: 15px;
    padding-left: 15px;
}
.portal.portal__bar-finder .bar-tpl-titel {
    font-size: 32px;
    font-weight: 400;
    border-bottom: 1px solid var(--grau-20);
}
.portal.portal__bar-finder h3 span {
    font-size: 24px;
    font-weight: 400;
}
.portal.portal__bar-finder .hinweis .list__element-mittel {
    border-radius: 6px 6px 0 0;
}
.portal.portal__bar-finder .hinweis p {
    font-size: 14px;
}
.portal.portal__bar-finder .hinweis .list__element-mittel .element__sort-titel {
    border-radius: 6px 6px 0 0;
}

.xxx--portal---padding,
.xxx--portal---padding-mod .xxx--portal--mod,
.xxx--portal---padding-block .xxx--portal--mod--block {
    padding: 15px;
}

.keyfacts li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
}
.keyfacts i {
    color: var(--green);
    margin-right: 10px;
    font-size: 20px;
}
.kontakt__footer a {
    color: var(--schwarz);
}
iframe.portal__video {
    height: 600px !important;
}

.xxx--portal-mod--block-knt--teaser-text a,
.xxx--portal-mod--block-knt--teaser-text p a {
    color: var(--blau);
}
.xxx--portal-mod--block-knt--teaser-text a:hover,
.xxx--portal-mod--block-knt--teaser-text p a:hover {
    color: var(--gelb);
}

/*
 *
 * RSS
 */

div.rss-items {
    border-top: 1px solid grey;
}

div.rss-items h6 {
    font-weight: bold;
}

div.rss table {
    width: 100%;
    margin-bottom: 10px;
}

div.rss a {
    text-decoration: underline;
}

div.rss-items p:nth-child(4) {
    color: grey;
    font-style: italic;
    text-align: right;
}

.rss .row {
    margin-left: 0;
}

.rss-text {
    padding-left: 0;
}


.rss-text img {
    float: left;
    width: 25%;
    margin-right: 10px;
    vertical-align: middle;
}

.rss-items {
    cursor: pointer;
}

.rss-items .rss-date,
.rss-items .btn-primary {
    float: right;
}


/*
 *
 * Druckansicht
 */

.druck_kopf {
    border-bottom: 2px solid #333;
}

.druck_kopf_links h4 {
    font-size: 14px;
}

.druck_kopf_links p {
    margin-bottom: 5px;
}

.druck_kopf_rechts h4 {
    border-bottom: 1px solid #333;
    font-size: 14px;
}

.druck_kopf_rechts p {
    margin-bottom: 5px;
}

.druck_kundendaten {
    border-bottom: 2px solid #333;
}

.druck_rechnungsanschrift h4 {
    font-size: 12px;
}

.druck_lieferanschrift h4 {
    height: 26px;
    font-size: 12px;
}

.druck_rechnungsanschrift label,
.druck_rechnungsanschrift p,
.druck_lieferanschrift label,
.druck_lieferanschrift p {
    margin-bottom: 0;
}

.druck_rechnungsanschrift .form-horizontal .form-group,
.druck_lieferanschrift .form-horizontal .form-group {
    margin-bottom: 5px;
}

.druck_bestelluebersicht h4 {
    font-size: 14px;
}

.druck_bestelluebersicht .table p {
    margin-bottom: 0;
}

.druck_bestelluebersicht .table thead th {
    padding: 5px;
}

.druck_bestelluebersicht .table tbody td {
    padding: 5px;
    vertical-align: middle;
}

.druck_bestelluebersicht {
    border-bottom: 2px solid #333;
}

.druck_bemerkung {
    border-bottom: 2px solid #333;
}

.druck_bemerkung h4 {
    font-size: 14px;
}

.druck_infotext {
    margin-top: 10px;
    font-weight: bold;
}

.druck_zahlung_lieferbedingung h4 {
    font-size: 14px;
}

.druck_zahlung_lieferbedingung {
    border-bottom: 2px solid #333;
}

.druck_button {
    margin-top: 20px;
}
/*################ David #################*/

.barfinder_portal_search .autocomplete input {
    width: 100% !important;
}

.barfinder_portal_spinner img {
    height: 34px;
}
/*################ /David #################*/

.gallist__standort .galbild_vorsch {
    position: relative;
    width: 96%;
    height: 100%;
}


.gallist__standort div.galbild_vorsch img {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    border-radius: 4px;
    cursor: pointer;
    object-fit: none;
}
.portal-kontakt a {
    color: #333;
}
/*
*
* Extra Small (XS)
*/

@media (max-width: 767px) {
    .portal.portal__bar-finder .xxx--portal-mod .form .row {
        flex-direction: column;
        margin-bottom: 0;
    }
    .portal.portal__bar-finder .xxx--portal-mod p span {
        font-size: 14px;
        font-weight: 500;
    }
    iframe.portal__video {
        height: 200px !important;
    }
}


/*
*
* Small (SM)
*/

@media (min-width: 768px) and (max-width: 991px) {
    iframe.portal__video {
        height: 350px !important;
    }
}


/*
*
* Medium (MD)
*/

@media (min-width: 992px) and (max-width: 1199px) {
    iframe.portal__video {
        height: 400px !important;
    }
}


/*
*
* Large (LG)
*/

@media (min-width: 1200px) {

}