





/*
 *
 * Buttons
 */

.btn-primary {
    background: var(--weiss);
    border: 1px solid var(--green) !important;
    box-shadow: 2px 2px 0px var(--blau);
    color: var(--grau);
}

.btn-primary:focus,
.btn-primary:hover {
    background: var(--gelb-5);
    border: 1px solid var(--green) !important;
    box-shadow: 2px 2px 0px var(--blau);
    color: var(--grau);
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open>.dropdown-toggle.btn-primary.focus,
.open>.dropdown-toggle.btn-primary:focus,
.open>.dropdown-toggle.btn-primary:hover {
    background-color: var(--weiss);
    border: 1px solid var(--green) !important;
}
.btn-info {
    color: var(--blau);
    background-color: var(--green);
    border: 1px solid var(--gelb);
}
.btn-info:hover {
    color: var(--weiss);
    background-color: var(--blau) !important;
    border: 1px solid var(--blau) !important;
}

.navbar {
    border-radius: 0;
}

.navbar li a:link,
.navbar li a:visited,
.navbar li a:hover,
.navbar li a:active,
.navbar li a:focus {
    font-size: 16px;
    text-decoration: none;
}

.navbar-default {
    background-color: #fff;
    border: none;
}

.navbar-default .navbar-nav > li > a {
    color: var(--weiss);
}

.navbar-default .navbar-nav > li > a:hover {
    color: var(--weiss);
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover {
    color: var(--weiss);
    background-color: var(--blau);
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
    color: var(--weiss);
    background-color: var(--blau);
}
.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li > a:hover {
    color: var(--weiss);
    background-color: var(--blau); }

.dropdown-menu > li > a {
    color: var(--weiss);
    text-decoration: none;
    background-color: var(--gelb-7);
}
.dropdown-menu > li > a span.xxx--elastic-search-nav--link-label {
    padding-right: 45px;
    font-size: 15px;
    color: var(--weiss);
}

.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
    color: var(--schwarz);
    text-decoration: none;
    background-color: var(--weiss);
}

.pagination > .active > a {
    background-color: var(--weiss);
    border-color: var(--weiss);
}

.xxx-pagination {
    font-family: 'Open Sans', sans-serif;
}

.navbar-default .navbar-collapse {
    border: none;
}

.navbar-default .navbar-toggle {
    border: none;
}

.navbar-default .navbar-toggle .icon-bar {
    width: 35px;
    height: 3px;
    margin-bottom: 5px;
    background-color: var(--weiss);
}

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
    background: none;
}

.breadcrumb > li,
.breadcrumb > li > a {
    color: #666;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
    color: var(--weiss);
    background: #fff;
}
button span {
    font-weight: 500 !important;
    font-size: 16px !important;
}
.kontakt-formular button i {
    font-size: 14px !important;
}
button i {
    color: var(--green);
    font-size: 20px !important;
}
.kontakt-formular button:hover i {
    color: var(--weiss);
}
.btn-default {
    color:  var(--grau);
    background-color: var(--gelb-5);
    border-color: var(--gelb);
}

.btn-default:hover {
    color:  var(--weiss);
    background-color: var(--gelb);
    border-color: var(--gelb);
}
.btn-default.active, .btn-default:active, .open>.dropdown-toggle.btn-default {
    color: var(--weiss);
    background-color: var(--gelb-5);
    border-color: var(--gelb);
}
.btn-default.focus, .btn-default:focus {
    color: var(--weiss);
    background-color: var(--gelb);
    border-color: var(--gelb);
}
.nav>li>a:focus, .nav>li>a:hover {
    background-color: var(--gelb);
}
.header-main-menu-ul-li div a {
    background-color: transparent !important;
    text-transform: uppercase;

}
.header-main-menu-ul-li > div {
    background-color: var(--blau);
    padding: 14px;
    border-left: 2px solid var(--weiss);
    border-radius: 6px 0 0 0;
}
.header-main-menu-ul-li div:hover {
    background-color: var(--gelb);
}
/*
.header-main-menu-ul-li div:hover a {
    color: var(--blau);
}*/

/* Pagination */

.pagination li.pagination-first a,
.pagination li.pagination-prev a,
.pagination li.pagination-next a,
.pagination li.pagination-last a {
    color: var(--green);
    background-color: var(--weiss);
    border: 5px solid var(--green);
    font-size: 30px;
    padding: 0 10px;
    line-height: 1;
}
.pagination li.pagination-first a:hover,
.pagination li.pagination-prev a:hover,
.pagination li.pagination-next a:hover,
.pagination li.pagination-last a:hover {
    color: var(--weiss);
    background-color: var(--green);
    border: 5px solid var(--green);
}
.pagination li.pagination-first a {
    border-radius: 6px 0px 0px 6px;
}
.pagination li.pagination-last a {
    border-radius: 0px 6px 6px 0px;
}
.pagination>li>a,
.pagination>li>span {
    position: relative;
    float: left;
    padding: 9px 12px;
    margin-left: 10px;
    line-height: 1;
    color: var(--blau);
    text-decoration: none;
    background-color: var(--weiss);
    border: 1px solid var(--green);
    font-size: 20px;
}
.pagination>li>a:focus, .pagination>li>a:hover, .pagination>li>span:focus, .pagination>li>span:hover {
    z-index: 2;
    color: var(--blau);
    background-color: var(--gelb-5);
    border-color: var(--green);
}
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    z-index: 3;
    color: var(--blau);
    cursor: default;
    background-color: var(--gelb-5);
    border-color: var(--gelb);
}
/*.pagination>.disabled>a, .pagination>.disabled>a:focus, .pagination>.disabled>a:hover, .pagination>.disabled>span, .pagination>.disabled>span:focus, .pagination>.disabled>span:hover {
    color: var(--grau);
    background-color: var(--green);
    border-color: var(--green);
}*/
/*
*
* Extra Small (XS)
*/

@media (max-width: 767px) {
    .pagination li.pagination-first a,
    .pagination li.pagination-last a {
        display: none;
    }
}


/*
*
* Small (SM)
*/

@media (min-width: 768px) and (max-width: 991px) {

}


/*
*
* Medium (MD)
*/

@media (min-width: 992px) and (max-width: 1199px) {

}


/*
*
* Large (LG)
*/

@media (min-width: 1200px) {

}