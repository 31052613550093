



/*
 *
 * Footer
 */

.foot_logos {
    text-align: right;
}

.foot_logos > a > img,
.foot_logos > img {
    padding: 10px;
}

div.foot1 {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    border-top: 1px solid #333;
}

div.foot2 > ul > li > a {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
}

div.foot2 > ul > li:hover > a {
    color: var(--weiss);
    font-family: 'Open Sans', sans-serif;
    background-color: #fff;
}
.footer {
    background-color: var(--blau);
    color: var(--weiss);
}
.footer__info .nav li a:hover {
    background-color: transparent;
}
.footer p {
    color: var(--weiss);
    margin-bottom: 0;
}

/*
*
* Extra Small (XS)
*/

@media (max-width: 767px) {
    .footer__img {
        text-align: end;
    }
}


/*
*
* Small (SM)
*/

@media (min-width: 768px) and (max-width: 991px) {
    .footer__img {
        text-align: end;
    }
}


/*
*
* Medium (MD)
*/

@media (min-width: 992px) and (max-width: 1199px) {

}


/*
*
* Large (LG)
*/

@media (min-width: 1200px) {

}