




/* SUGGESTION */
.xxx--elastic-search-suggestion {
    position: relative;
}

.xxx--elastic-search-suggestion--list-block {
    position: absolute;
    display: block;
    padding: 0;
    z-index: 1000;
    background: #fff;
}

.xxx--elastic-search-suggestion--list-label {
    display: block;
    width: 100%;
    padding: 10px 15px;
    font-size: 12px;
    color: #fff;
    background: var(--weiss);
}

.xxx--elastic-search-suggestion--list {
    width: 100%;
    padding: 0;
    background: #fff;
    z-index: 100;
}

.xxx--elastic-search-suggestion--list + .xxx--elastic-search-suggestion--list {
    border-top: 2px solid var(--weiss);
}

.xxx--elastic-search-suggestion--list-link {
    padding: 10px 15px;
    font-size: 14px;
    color: #666;
    transition: background 300ms ease, color 300ms ease;
}

.xxx--elastic-search-suggestion--list-item:hover .xxx--elastic-search-suggestion--list-link,
.xxx--elastic-search-suggestion--list-item---selected .xxx--elastic-search-suggestion--list-link {
    font-weight: bold;
    background: #ccc;
    color: #333;
}

.xxx--elastic-search-suggestion--product--list-image-col {
    padding-left: 0;
    padding-right: 0;
    max-width: 100px;
    overflow: hidden;
}

.xxx--elastic-search-suggestion--product--list-image-src {
    position: absolute;
    width: 100%;
}

/* RESULT > CONTROL */
.xxx--elastic-search-result--control--view {
    margin-right: 15px;
}

.xxx--elastic-search-result--control--view.xxx--list .xxx--list-item + .xxx--list-item::before,
.xxx--elastic-search-result--control--limit.xxx--list .xxx--list-item + .xxx--list-item::before {
    content: ' | ';
    color: var(--blau);
}

.xxx--elastic-search-result--control--limit.xxx--list .xxx--list-item + .xxx--list-item {
    margin-left: 5px;
}

.xxx--elastic-search-result--control--btn {
    background: var(--weiss);
    transition: background 300ms ease, color 300ms ease, border 300ms ease;
}
.xxx--elastic-search-result--control--btn span {
    color: var(--green);
}
.xxx--elastic-search-result--control--btn:hover span {
    color: var(--blau);
}

.xxx--elastic-search-result--control--btn:hover {
    background: var(--gelb-5);
}
.xxx--elastic-search-result--control--btn.xxx---is-active {
    color:var(--blau) !important;
    border: 1px solid var(--green) !important;
    box-shadow: 2px 2px 0px var(--gelb);
    background: var(--gelb-5);
}

.xxx--elastic-search-result--control--btn.xxx---is-active span {
    color:var(--blau) !important;
    background: var(--weiss);
}

.xxx--elastic-search-result--control--q .xxx--elastic-search-result--control--btn {
    margin-right: 10px;
}

.xxx--elastic-search-result--control--q-amount {
    margin-left: 15px;
}

.xxx--elastic-search-result--control--q-close {
    padding-left: 5px;
}

.suggestion__block-ul a {
    color: var(--schwarz);
}

@media (min-width: 768px) {
    .xxx--elastic-search-result--control--sort-attribute {
        max-width: 50%;
    }
}
/*
*
* Extra Small (XS)
*/

@media (max-width: 767px) {
    .control__limit {
        margin-top: 15px !important;
    }
}


/*
*
* Small (SM)
*/

@media (min-width: 768px) and (max-width: 991px) {

}


/*
*
* Medium (MD)
*/

@media (min-width: 992px) and (max-width: 1199px) {

}


/*
*
* Large (LG)
*/

@media (min-width: 1200px) {

}