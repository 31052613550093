



/* SQUARE */
.xxx--elastic-search-nav--square--list-item {
    padding-top: 15px;
    padding-bottom: 15px;
}

.xxx--elastic-search-nav--square--link {
    position: relative;
    display: block;
    overflow: hidden;
    border: thin solid var(--green);
}

.xxx--elastic-search-nav--square--link-image,
.xxx--elastic-search-nav--square--link-label,
.xxx--elastic-search-nav--square--link-count {
    position: absolute;
}

.xxx--elastic-search-nav--square--link-label,
.xxx--elastic-search-nav--square--link-count {
    padding: 5px 7px;
    z-index: 10;
    background: var(--green);
    color: #fff;
}

.xxx--elastic-search-nav--square--link-label {
    bottom: 0;
    left: 0;
}

.xxx--elastic-search-nav--square--link-count {
    bottom: 0;
    right: 0;
}

.xxx--elastic-search-nav--square--list-item .xxx---square-background {
    background-size: contain;
}