
/*
 *
 * ELEMENTS
 */

iframe {
    width: 100% !important;
}

iframe[ng-src*="3xios/beleg1.php"] {
    min-height: 500px;
}


/**
 *
 * @package bootstrap
 * @extension
 */

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-fat {
    font-weight: bold;
}


/**
 *
 * @package reboot
 * @modifier
 */

.reboot---padding,
.reboot---padding-all,
.reboot---padding-all .container,
.reboot---padding-all .row,
.reboot---padding-all *[class*="col-"],
.reboot---padding-all-panel.panel-group,
.reboot---padding-all-panel .panel-group,
.reboot---padding-all-container.container,
.reboot---padding-all-container .container,
.reboot---padding-all-row.row,
.reboot---padding-all-row .row,
.reboot---padding-all-col[class*="col-"],
.reboot---padding-all-col *[class*="col-"] {
    padding: 0;
}

.reboot---margin,
.reboot---margin-all,
.reboot---margin-all .row,
.reboot---margin-all *[class*="navbar-"],
.reboot---margin-all .panel-group,
.reboot---margin-all-row.row,
.reboot---margin-all-row .row,
.reboot---margin-all-panel[class*="panel-"],
.reboot---margin-all-panel *[class*="panel-"],
.reboot---margin-all-panel .panel-group,
.reboot---margin-all-navbar[class*="navbar-"],
.reboot---margin-all-navbar *[class*="navbar-"] {
    margin: 0;
}

.reboot---border-radius,
.reboot---border-radius * {
    border-radius: 0 !important;
}


/**
 * flex
 * @package xxx
 * @component
 */

.xxx--flex-container {
    display: flex;
    flex-direction: row;
    max-width: 1200px;
    margin: 0 auto;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---fluid {
    width: 100%;
    max-width: unset;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---row {
    flex-direction: row;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---row-reverse {
    flex-direction: row-reverse;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---col {
    flex-direction: column;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---col-reverse {
    flex-direction: column-reverse;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---wrap {
    flex-wrap: wrap;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---wrap-reverse {
    flex-wrap: wrap-reverse;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---justify-center {
    justify-content: center;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---align-item-stretch {
    align-items: stretch;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---align-item-start {
    align-items: flex-start;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---align-item-end {
    align-items: flex-end;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---align-item-center {
    align-items: center;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---align-content-stretch {
    align-content: stretch;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---align-content-start {
    align-content: flex-start;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---align-content-end {
    align-content: flex-end;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---align-content-center {
    align-content: center;
}

@media (max-width: 767px) {
    .xxx-xs--flex-container {
        display: flex;
        flex-direction: row;
        margin: 0 auto;
    }

    *[class*="xxx-"][class*="--flex-container"].xxx-xs--flex---col {
        flex-direction: column;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .xxx-sm--flex-container {
        display: flex;
        flex-direction: row;
        max-width: 750px;
        margin: 0 auto;
    }

    *[class*="xxx-"][class*="--flex-container"].xxx-sm--flex---col {
        flex-direction: column;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .xxx-md--flex-container {
        display: flex;
        flex-direction: row;
        max-width: 970px;
        margin: 0 auto;
    }

    *[class*="xxx-"][class*="--flex-container"].xxx-md--flex---col {
        flex-direction: column;
    }
}

@media (min-width: 1200px) {
    .xxx-lg--flex-container {
        display: flex;
        flex-direction: row;
        max-width: 1170px;
        margin: 0 auto;
    }

    *[class*="xxx-"][class*="--flex-container"].xxx-lg--flex---col {
        flex-direction: column;
    }
}

.xxx--flex-container > *,
.xxx--flex-item {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 8.33%;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---base-1 {
    flex-basis: 8.33%;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---base-2 {
    flex-basis: 16.66%;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---base-3 {
    flex-basis: 25%;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---base-4 {
    flex-basis: 33.33%;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---base-5 {
    flex-basis: 41.66%;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---base-6 {
    flex-basis: 50%;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---base-7 {
    flex-basis: 58.33%;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---base-8 {
    flex-basis: 66.66%;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---base-9 {
    flex-basis: 75%;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---base-10 {
    flex-basis: 83.33%;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---base-11 {
    flex-basis: 91.66%;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---base-12 {
    flex-basis: 100%;
}

@media (max-width: 767px) {
    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---base-1 {
        flex-basis: 8.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---base-2 {
        flex-basis: 16.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---base-3 {
        flex-basis: 25%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---base-4 {
        flex-basis: 33.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---base-5 {
        flex-basis: 41.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---base-6 {
        flex-basis: 50%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---base-7 {
        flex-basis: 58.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---base-8 {
        flex-basis: 66.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---base-9 {
        flex-basis: 75%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---base-10 {
        flex-basis: 83.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---base-11 {
        flex-basis: 91.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---base-12 {
        flex-basis: 100%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---base-1 {
        flex-basis: 8.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---base-2 {
        flex-basis: 16.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---base-3 {
        flex-basis: 25%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---base-4 {
        flex-basis: 33.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---base-5 {
        flex-basis: 41.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---base-6 {
        flex-basis: 50%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---base-7 {
        flex-basis: 58.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---base-8 {
        flex-basis: 66.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---base-9 {
        flex-basis: 75%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---base-10 {
        flex-basis: 83.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---base-11 {
        flex-basis: 91.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---base-12 {
        flex-basis: 100%;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---base-1 {
        flex-basis: 8.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---base-2 {
        flex-basis: 16.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---base-3 {
        flex-basis: 25%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---base-4 {
        flex-basis: 33.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---base-5 {
        flex-basis: 41.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---base-6 {
        flex-basis: 50%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---base-7 {
        flex-basis: 58.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---base-8 {
        flex-basis: 66.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---base-9 {
        flex-basis: 75%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---base-10 {
        flex-basis: 83.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---base-11 {
        flex-basis: 91.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---base-12 {
        flex-basis: 100%;
    }
}

@media (min-width: 1200px) {
    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---base-1 {
        flex-basis: 8.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---base-2 {
        flex-basis: 16.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---base-3 {
        flex-basis: 25%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---base-4 {
        flex-basis: 33.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---base-5 {
        flex-basis: 41.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---base-6 {
        flex-basis: 50%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---base-7 {
        flex-basis: 58.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---base-8 {
        flex-basis: 66.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---base-9 {
        flex-basis: 75%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---base-10 {
        flex-basis: 83.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---base-11 {
        flex-basis: 91.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---base-12 {
        flex-basis: 100%;
    }
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---grow-0 {
    flex-grow: 0;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---grow-1 {
    flex-grow: 1;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---grow-2 {
    flex-grow: 2;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---grow-3 {
    flex-grow: 3;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---shrink-0 {
    flex-shrink: 0;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---shrink-1 {
    flex-shrink: 1;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---shrink-2 {
    flex-shrink: 2;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---shrink-3 {
    flex-shrink: 3;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---order-1 {
    order: 1;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---order-2 {
    order: 2;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---order-3 {
    order: 3;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---order-4 {
    order: 4;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---order-5 {
    order: 5;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---order-6 {
    order: 6;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---order-7 {
    order: 7;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---order-8 {
    order: 8;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---order-9 {
    order: 9;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---order-10 {
    order: 10;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---order-11 {
    order: 11;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---order-12 {
    order: 12;
}

@media (max-width: 767px) {
    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---order-1 {
        order: 1;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---order-2 {
        order: 2;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---order-3 {
        order: 3;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---order-4 {
        order: 4;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---order-5 {
        order: 5;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---order-6 {
        order: 6;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---order-7 {
        order: 7;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---order-8 {
        order: 8;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---order-9 {
        order: 9;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---order-10 {
        order: 10;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---order-11 {
        order: 11;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---order-12 {
        order: 12;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---order-1 {
        order: 1;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---order-2 {
        order: 2;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---order-3 {
        order: 3;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---order-4 {
        order: 4;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---order-5 {
        order: 5;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---order-6 {
        order: 6;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---order-7 {
        order: 7;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---order-8 {
        order: 8;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---order-9 {
        order: 9;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---order-10 {
        order: 10;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---order-11 {
        order: 11;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---order-12 {
        order: 12;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---order-1 {
        order: 1;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---order-2 {
        order: 2;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---order-3 {
        order: 3;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---order-4 {
        order: 4;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---order-5 {
        order: 5;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---order-6 {
        order: 6;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---order-7 {
        order: 7;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---order-8 {
        order: 8;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---order-9 {
        order: 9;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---order-10 {
        order: 10;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---order-11 {
        order: 11;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---order-12 {
        order: 12;
    }
}

@media (min-width: 1200px) {
    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---order-1 {
        order: 1;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---order-2 {
        order: 2;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---order-3 {
        order: 3;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---order-4 {
        order: 4;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---order-5 {
        order: 5;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---order-6 {
        order: 6;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---order-7 {
        order: 7;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---order-8 {
        order: 8;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---order-9 {
        order: 9;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---order-10 {
        order: 10;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---order-11 {
        order: 11;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---order-12 {
        order: 12;
    }
}


/**
 * page
 * @package xxx
 * @component
 */

.xxx--page-header {
    position: relative;
}

/*.xxx--page-header--block {
    padding: 25px 0;
}*/

.xxx--scroll-sticky .xxx--page-header--block {
    padding: 0;
}


/**
 * language
 * @package xxx
 * @component
 */

.xxx--language--list .xxx--list-item + .xxx--list-item {
    margin-left: 10px;
}

.xxx--language--block {
    opacity: .3;
    background: #ddd;
    border-radius: 5px;
    overflow: hidden;
}

.xxx--scroll-sticky .xxx--language--list {
    position: absolute;
    right: 0;
    z-index: 1000;
}

.xxx--language--block.xxx---is-active,
.xxx--language--block:hover {
    opacity: 1;
    transition: opacity 300ms ease;
}

.xxx--language--icon,
.xxx--language--label {
    font-size: 30px;
}


/**
 * map
 * @package xxx
 * @component
 */

.xxx--map {
    display: block;
    width: 100%;
    min-height: 200px;
    margin-bottom: 30px;
    border: 0 none;
    outline: 1px solid #ccc;
}


/**
 * list
 * @package xxx
 * @component
 */

.xxx--list---cleared {
    list-style: none;
    padding: 0;
    margin: 0;
}

/*.xxx--list---level .xxx--list---level {
    padding-left: 15px;
}*/

.xxx--list-item > .xxx--collapse {
    border-top: thin solid var(--gelb);
    border-bottom: thin solid #666;
}

.xxx--list-item > .xxx--collapse.xxx---is-collapsed {
    border-top: thin solid #fff;
    border-bottom: thin solid #fff;
}

.xxx--list-item:last-of-type > .xxx--collapse {
    border-bottom: thin solid #fff !important;
}
.xxx--elastic-search-nav--list-item.xxx--list-item.xxx--list-item---level {
    background-color: var(--gelb-7);
}
.xxx--elastic-search-nav--list-item.xxx--list-item.xxx--list-item---level.xxx---level-1 a {
    padding-left: 20px;
}
.xxx--elastic-search-nav--list-item.xxx--list-item.xxx--list-item---level.xxx---level-2 {
    background-color: var(--blau-7);
}
.xxx--elastic-search-nav--list-item.xxx--list-item.xxx--list-item---level.xxx---level-2 a {
    padding-left: 25px;
}
/*.xxx--elastic-search-nav--list-item.xxx--list-item.xxx--list-item---level.xxx---level-3 {
    background-color: var(--gelb);
}*/
.xxx--elastic-search-nav--list-item.xxx--list-item.xxx--list-item---level.xxx---level-3 a {
    padding-left: 15px;
}
/*.xxx--elastic-search-nav--list-item.xxx--list-item.xxx--list-item---level.xxx---level-4 {
    background-color: var(--gelb-7);
}*/
.xxx--elastic-search-nav--list-item.xxx--list-item.xxx--list-item---level.xxx---level-4 a {
    padding-left: 20px;
}
/*.xxx--elastic-search-nav--list-item.xxx--list-item.xxx--list-item---level.xxx---level-5 {
    background-color: var(--gelb);
}*/
.xxx--elastic-search-nav--list-item.xxx--list-item.xxx--list-item---level.xxx---level-5 a {
    padding-left: 25px;
}
/*.xxx--elastic-search-nav--list-item.xxx--list-item.xxx--list-item---level.xxx---level-6 {
    background-color: var(--gelb-7);
}*/
/*.xxx--elastic-search-nav--list-item.xxx--list-item.xxx--list-item---level.xxx---level-7 {
     background-color: var(--gelb);
 }*/
/*.xxx--elastic-search-nav--list-item.xxx--list-item.xxx--list-item---level.xxx---level-8 {
    background-color: var(--gelb-7);
}*/


/**
 * table
 * @package xxx
 * @component
 */

.xxx--table {
    width: 100%;
}

.xxx--table---th-light th {
    font-weight: lighter;
}


/**
 * img-box
 * @package xxx
 * @component
 */

.xxx--img-box {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.xxx--img-box---radial {
    border-radius: 3px;
}

.xxx--img-box---hover-shadow {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    transition: box-shadow 300ms ease;
}

.xxx--img-box---hover-shadow:hover {
    box-shadow: 0 0 3px rgba(0, 0, 0, .6);
}

.xxx--img-box--title {
    font-size: 14px;
}

.xxx--img-box--title---absolute-top-right {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    padding: 15px;
    background: rgba(255, 255, 255, .8);
    z-index: 10;
}

.xxx--img-box--title---bottom-plain-grey {
    display: block;
    padding: 5px 8px;
    background: #eee;
    border-top: thin solid #999;
    z-index: 10;
}

.xxx--img-box--image {
    display: block;
    width: 100%;
}

*[class*="xxx--img-box-image---max-height-"],
.xxx--img-box--image.xxx--img-box--image---normal-size {
    width: auto;
}

.xxx--img-box-image---max-height-200 {
    max-height: 200px;
}

.xxx--img-box-image---max-height-300 {
    max-height: 300px;
}

.xxx--img-box-image---max-height-400 {
    max-height: 400px;
}

.xxx--img-box--image---center {
    margin: 0 auto;
}

.xxx--img-box--inner {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    margin: 10px;
    outline: thin solid #fff;
}


/**
 * product
 * @package xxx
 * @component
 */

.xxx--product--sku:before {
    content: '# ';
}

.xxx--product--sku {
    font-size: 10px;
    color: #999;
}
