





/*
 *
 * Warenkorb
 */

.wk_schnellerfassung {
    margin-bottom: 30px;
}

.wk_schnellerfassung > div {
    margin-bottom: 10px;
}

.wk_schnellerfassung .input-group > .input-group-addon {
    min-width: 100px;
}

.wk_schnellerfassung .input-group > .form-control {
    text-align: center;
}

.wk_stufen {
    margin-bottom: 30px;
}

.wk_stufen .symbol {
    float: left;
    width: 30px;
    height: 30px;
    line-height: 30px;
    color: #333;
    font-size: 25px;
    text-align: center;
}

.wk_stufen .symbol.active {
    color: var(--gelb);
}

.wk_stufen .symbol.success {
    color: #006400;
}

.wk_stufen .title {
    padding-left: 35px;
    line-height: 30px;
    font-size: 20px;
}

.wk_stufen .title.active {
    color: var(--gelb);
}

.wk_stufen .title.success {
    color: #006400;
}

.wk_footer {
    margin-top: 20px;
}

.wk_footer > div {
    margin-bottom: 10px;
}

.wk_login_bereich .panel .panel-title > input {
    margin-right: 10px;
}

.wk_login_bereich .panel .main {
    height: 165px;
}

.wk_login_bereich .wk_login_panel > .main > div {
    margin-bottom: 10px;
}

.wk_belegsumme {
    padding: 5px;
    color: var(--weiss);
    border: 1px solid var(--green);
    background-color: var(--gelb);
    font-weight: bold;
    text-align: right;
    border-radius: 6px;
}

.wk_versandcontainer {
    margin: 20px 0;
    padding: 5px;
    background-color: #eee;
    border: 1px solid #ccc;
}

.wk_versandcontainer .checkbox input {
    margin-right: 5px;
}

.wk_bestellabschluss_bemerkung_container {
    padding: 10px;
    border: 1px solid #ccc;
}

.wk_bestellabschluss_btn > div {
    margin-bottom: 10px;
}

.wk_pos_image_container {
    position: relative;
    width: 100%;
    padding-top: 100%;
}

.wk_pos_image_container img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.wk_pos_zeile {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
}

.wk_pos_zeile > div {
    padding-top: 10px;
}

.wk_pos_zeile .wk_pos_menge_zelle {
    line-height: 34px;
    font-size: 16px;
    font-weight: bold;
}

.wk_pos_zeile .wk_pos_gesamtpreis_zelle {
    line-height: 34px;
    font-weight: bold;
    font-size: 16px;
    text-align: right;
}

.wk_pos_zeile .wk_pos_delete_zelle {
    font-size: 16px;
    text-align: center;
}

.wk_pos_zeile .wk_pos_info_zelle .titel {
    padding-bottom: 10px;
}

.wk_pos_zeile .wk_pos_info_zelle .titel p {
    font-weight: bold;
    font-size: 16px;
}

.wk_pos_zeile .wk_pos_change_link a {
    text-decoration: underline;
}

.wk_pos_smart_foot {
    padding: 10px;
    background-color: #eee;
}

.wk_table > thead > tr > th {
    text-align: center;
}

.wk_table > tbody > tr > td {
    vertical-align: middle;
    text-align: center;
}

.wk_table .titel p {
    margin: 0;
    font-weight: bold;
    text-decoration: underline;
}

.wk_table td.info_spalte {
    text-align: left;
}

.belegliste_kopf_pos {
    width: 5%;
}

.belegliste_kopf_artikelnummer {
    width: 20%;
}

.belegliste_kopf_menge {
    text-align: center;
}

.belegliste_inhalt_menge {
    text-align: center;
}

.belegliste_kopf_mengeneinheit {
    text-align: center;
}

.belegliste_inhalt_mengeneinheit {
    text-align: center;
}

.belegliste_kopf_preiseinheit {
    text-align: right;
}

.belegliste_inhalt_preiseinheit {
    text-align: right;
}

.belegliste_kopf_einzelpreis {
    text-align: right;
}

.belegliste_inhalt_einzelpreis {
    text-align: right;
}

.belegliste_kopf_rabatt {
    text-align: right;
}

.belegliste_inhalt_rabatt {
    text-align: right;
}

.belegliste_kopf_gesamtpreis {
    text-align: right;
}

.belegliste_inhalt_gesamtpreis {
    text-align: right;
}

.belegliste_kopf_bestand {
    text-align: right;
}

.belegliste_inhalt_bestand {
    width: 5%;
    max-width: 5%;
    text-align: center;
}

#continue_shopping,
#BestellenWeiterKL,
#AnmeldenKL {
    float: left;
    width: 148px;
    height: 30px;
    margin-top: 5px;
    line-height: 30px;
    border: 1px solid var(--weiss);
    border-radius: 4px;
    background: var(--weiss);
    color: #fff;
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    cursor: pointer;
}

.td_warenkorb1_untere_zeile {
    width: 650px;
}

.wk_registrieren_button {
    width: 150px;
    height: 30px;
    margin-top: 20px;
    line-height: 30px;
    border: 1px solid var(--weiss);
    border-radius: 4px;
    background: var(--weiss);
    text-align: center;
    cursor: pointer;
}

.wk_registrieren_button > a {
    line-height: 30px;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
}

.textfeld1 {
    height: 18px;
    font-size: 8pt;
}

.textarea1 {
    font-size: 8pt;
}

.font8 {
    font-size: 8pt;
    font-weight: normal;
}

.font9 {
    font-size: 9pt;
    font-weight: normal;
}

.font10 {
    font-size: 10pt;
    font-weight: normal;
}

.font8b {
    font-size: 8pt;
    font-weight: bold;
}

.font9b {
    font-size: 9pt;
    font-weight: bold;
}

.font10b {
    font-size: 10pt;
    font-weight: bold;
}

.font9blue {
    color: #00F;
    font-size: 9pt;
    font-weight: bold;
}

.font9red {
    color: #C00;
    font-size: 9pt;
}

.font9green {
    color: #006400;
    font-size: 9pt;
}

.font9greenb {
    color: #006400;
    font-size: 9pt;
    font-weight: bold;
}

.copyright {
    font-weight: 300;
    font-size: 12px;
}

.table_rahmentab {
    width: 100%;
    font-size: 12px;
}

.table_mitte_inhalt {
    font-size: 12px;
}

.bg_inhalt {
    width: 100%;
    background-color: #fff;
}

.mitte {
    width: 100%;
}

.td_mitte_inhalt_tabkopf {
    border-bottom: 1px solid #000;
    color: #000;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}

.td_mitte_inhalt {
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
}

.warenk_table_bestellbereich {
    padding: 3px;
}

.warenk_table_bestellflaeche {
    margin-bottom: 20px;
    padding: 2px 2px 2px 0;
}

.warenk_td_bestell_font {
    color: #000;
    font-size: 10pt;
    font-weight: 300;
}

.warenk_table_bestellung_kopf {
    margin-bottom: 3px;
    margin-top: 10px;
    padding: 3px;
    border-bottom: 1px solid black;
}

.warenk_tr_kundendaten {
    width: 100%;
    color: #000;
    font-size: 9pt;
    font-weight: 300;
}

.warenk_tr_bestellung_kopf {
    color: #000;
    font-size: 9pt;
    font-weight: 300;
    border-bottom: 1px solid black;
}

.warenk_tr_bestellung_ohneInhalt {
    color: #000;
    font-size: 9pt;
    font-weight: 300;
    border-spacing: 3px;
    border-collapse: separate;
}

.warenk_table_bestellung_fuss {
    margin-top: 3px;
    margin-bottom: 3px;
}

.warenk_td_bestell_buttons {
    margin-top: 5px;
    font-size: 9pt;
    font-weight: normal;
}

.seiten_ueberschrift {
    width: 100%;
    font-size: 12pt;
    font-weight: 300;
}

.table_formularbox {
    margin: 2px;
    padding: 2px;
}

.td_formular_kopf {
    color: #000;
    font-size: 10pt;
    font-weight: 300;
}

.td_formular_beschriftung {
    width: 100px;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
}

.td_formular_text {
    font-size: 9pt;
    font-weight: 300;
}

.form_meldung_td {
    color: red;
    font-size: 10pt;
    font-weight: 300;
}

.beleg_mitte {
    margin-top: 7px;
    padding: 5px;
    background-color: #fff;
}

.fontbelegarchiv {
    color: #000;
    font-size: 9pt;
    font-weight: 300;
}

.warenk_lieferbedingung {
    color: #000;
    font-size: 9pt;
    font-weight: 300;
}

.table_warenkorb_teil2 {
    margin-top: 9px;
}

.select_versandarten {
    border: 1px solid #c0c0c0;
    color: #13335c;
    font-family: 'Roboto', sans-serif;
    font-size: 8pt;
}

.select_zahlungsarten {
    width: 170px;
    border: 1px solid #c0c0c0;
    color: #13335c;
    font-family: 'Roboto', sans-serif;
    font-size: 8pt;
}

.beleg_lfb_texte_table {
    width: 100%;
}

.beleg_lfb_texte_td1 {
    font-family: 'Roboto', sans-serif;
    font-size: 9pt;
    font-weight: 300;
}

.beleg_lfb_texte_td2 {
    font-family: 'Roboto', sans-serif;
    font-size: 9pt;
    font-weight: normal;
}

.beleg_zb_texte_table {
    width: 100%;
    margin-left: 3px;
}

.beleg_zb_texte_td1 {
    font-family: 'Roboto', sans-serif;
    font-size: 9pt;
    font-weight: 300;
}

.beleg_zb_texte_td2 {
    font-family: 'Roboto', sans-serif;
    font-size: 9pt;
    font-weight: normal;
}

.wk1_a_textfeld1 {
    width: 50px;
    height: 18px;
    border: 1px solid #6f6f6f;
    font-size: 8pt;
}

.td_warenkorb_buttonleiste1_aktiv_variabel {
    width: 125px;
    height: 40px;
}

.td_warenkorb_buttonleiste1_passiv_variabel {
    width: 125px;
    height: 40px;
}

.td_warenkorb_buttonleiste2_aktiv_variabel {
    width: 125px;
}

.td_warenkorb_buttonleiste2_passiv_variabel {
    width: 125px;
}

.td_warenkorb_buttonleiste3_aktiv_variabel {
    width: 125px;
}

.td_warenkorb_buttonleiste3_passiv_variabel {
    width: 125px;
}

.td_warenkorb_buttonleiste4_aktiv_variabel {
    width: 125px;
}

.td_warenkorb_buttonleiste4_passiv_variabel {
    width: 125px;
}

.table_warenkorb_buttonleiste {
    width: 100%;
    height: 50px;
    margin-top: 8px;
    border-bottom: 1px solid #c0c0c0;
}

.td_warenkorb_buttonleiste1_aktiv {
    width: 125px;
    height: 40px;
    background: url('../../3xios/images/button_wk1_a.png') no-repeat;
}

.td_warenkorb_buttonleiste1_passiv {
    width: 125px;
    height: 40px;
    background: url('../../3xios/images/button_wk1_p.png') no-repeat;
}

.td_warenkorb_buttonleiste2_aktiv {
    width: 125px;
    background: url('../../3xios/images/button_wk2_a.png') no-repeat;
}

.td_warenkorb_buttonleiste2_passiv {
    width: 125px;
    background: url('../../3xios/images/button_wk2_p.png') no-repeat;
}

.td_warenkorb_buttonleiste3_aktiv {
    width: 125px;
    background: url('../../3xios/images/button_wk3_a.png') no-repeat;
}

.td_warenkorb_buttonleiste3_passiv {
    width: 125px;
    background: url('../../3xios/images/button_wk3_p.png') no-repeat;
}

.td_warenkorb_buttonleiste4_aktiv {
    width: 125px;
    background: url('../../3xios/images/button_wk4_a.png') no-repeat;
}

.td_warenkorb_buttonleiste4_passiv {
    width: 125px;
    background: url('../../3xios/images/button_wk4_p.png') no-repeat;
}

.td_warenkorb_buttonleiste1_zelle_unten {
    height: 10px;
}

select#sel_lfb.select_versandarten {
    width: 170px;
    height: 20px;
}

.abstand_summenfeld2 {
    padding-right: 50px;
}

.td_warenkorb1_untere_zeile {
    width: 65%;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
}

.meldungstext {
    color: #f00;
}

.tr_mitte_inhalt_0 {
    padding: 10px 0;
}

.table_warenkorb1_untere_zeile {
    margin-top: 15px;
}

.warenk_td_summen {
    padding-left: 50px;
}

.button_fett_konto {
    width: 207px;
    height: 30px;
    margin-top: 5px;
    margin-right: 12px;
    line-height: 30px;
    background: #b22413;
    color: #fff;
    font-size: 12px;
    font-weight: 300;
    text-align: center;
}

#sm_regneu,
#Zurueck,
#Bestellen,
#Verwerfen,
#Aendern,
#ZurueckFensterZu,
#sm_los_pw {
    width: 150px;
    height: 30px;
    margin-top: 5px;
    margin-right: 12px;
    line-height: 30px;
    border: 1px solid var(--weiss);
    border-radius: 4px;
    background: var(--weiss);
    color: #fff;
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    cursor: pointer;
    -webkit-appearance: none;
}

#WeiterWK,
#NeukundeRegistrieren,
#Anmelden,
#Abmelden {
    float: right;
    width: 148px;
    height: 33px;
    margin-top: 5px;
    margin-right: 5px;
    padding: 0 5px 8px 5px;
    line-height: 30px;
    border: 1px solid var(--weiss);
    border-radius: 4px;
    background: var(--weiss);
    color: #fff;
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    cursor: pointer;
    -webkit-appearance: none;
}

#WeiterWK2,
#WeiterWK3_ak,
#Drucken {
    float: right;
    width: 150px;
    height: 30px;
    margin-right: 12px;
    line-height: 30px;
    border: 1px solid var(--weiss);
    border-radius: 4px;
    background: var(--weiss);
    color: #fff;
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    cursor: pointer;
    -webkit-appearance: none;
}

#WeiterWK3_nk {
    width: 150px;
    height: 30px;
    margin-top: 5px;
    margin-right: 12px;
    line-height: 30px;
    border: 1px solid var(--weiss);
    border-radius: 4px;
    background: var(--weiss);
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    cursor: pointer;
}

.select_beleg2 {
    width: 100%;
    max-width: 195px;
    padding-top: 3px;
    padding-left: 5px;
    border: 1px solid #aaa;
    background-color: #fff;
    box-shadow: inset 0 0 8px #ccc;
    text-align: left;
}

.td_formular_kopf_beleg2 {
    height: 35px;
    max-height: 40px;
    padding: 5px 0 0 8px;
    line-height: 12px;
    vertical-align: top;
    background: url('images/hg-title.png');
    font-size: 11pt;
    text-align: left;
}

input.textfeld_wk_regstartbereich,
input#Artikelnummer_suche,
input#wk1_a_textfeld1,
input.textfeld_registrieren,
input.textfeld1,
input.textfeld_beleg2,
input.regi_textfeld_al,
select.select_registrieren,
select.regi_select_al,
select.select_zahlungsarten,
select.select_beleg2 {
    display: block;
    height: 30px;
    padding: 0 12px;
    line-height: 30px;
    line-height: 1.42857143;
    font-size: 14px;
    color: #555;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #fff none;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

select.select_registrieren,
select.regi_select_al {
    width: 200px;
}

#tx_pw1,
#tx_pw2 {
    max-width: 200px;
}

input.button_normal,
input#uebernehmen,
input#sm_import,
input#zahlen {
    width: 148px;
    height: 30px;
    margin-top: 5px;
    line-height: 30px;
    border: 1px solid var(--weiss);
    background: var(--weiss);
    color: #fff;
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    -webkit-appearance: none;
}

input.button_normal#SeiteDrucken {
    float: right;
    margin: 2px;
}

input.button_normal#Zu {
    float: left;
    margin: 2px;
}

.warenk_lieferbedingung td {
    padding-top: 5px;
}

.wk_zahlungslink_button {
    display: contents;
}

.wk_footer .checkbox a {
    color: var(--weiss);
    text-decoration: underline;
}
.table>thead>tr>th {
    vertical-align: bottom;
    border-bottom: 2px solid var(--gelb);
}
.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: var(--gelb-5);
}
#td_at_b1 table {
    border: 1px solid var(--gelb);
    border-radius: 6px;
}
table.table input.form-control,
.wk_pos_zeile input.form-control {
    height: 36px;
    box-sizing: border-box;
    border: 1px solid var(--gelb);
    border-radius: 6px;
    box-shadow: none;
}
table.table i {
    color: var(--green);
}
.wk_footer .checkbox a,
.wk_footer  a,
.wk_footer  button {
    color: var(--green);
    font-weight: 600;
}
td#td_at_b1 form a.btn.btn-default {
    color: var(--green);
}
td#td_at_b1 form a.btn.btn-default:hover {
    color: var(--weiss);
}