/*
 *
 * Artikeldetail
 */

.artdetail_blaettern_icon {
    color: var(--weiss);
    font-size: 20pt;
    cursor: pointer;
}

div.artikeldetail_info div {
    min-height: 20px;
    padding-bottom: 10px;
    font-size: 14px;
}

div.artikeldetail_info h4 {
    font-weight: 300;
    font-size: 18px;
}

.artikeldetail_hauptbild_container {
    position: relative;
    /*padding-top: 100%;*/
    /*border: 1px solid #333;*/
    border: none;
    border-radius: 4px;
}

.artikeldetail_hauptbild_container > img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    border-radius: 4px;
    cursor: zoom-in;
}

#artikeldetail_overlay {
    display: none;
    position: absolute;
    top: 0;
    right: -100%;
    z-index: 10;
    width: 100%;
    height: 100%;
    border: 1px solid black;
    background: #fff no-repeat;
    background-size: 200%;
}

.artikeldetail_alternativbilder_container {
    position: relative;
    /*padding-top: 73%;*/
    /*border: 1px solid #333;*/
    border-radius: 4px;
}

.artikeldetail_alternativbilder_container > img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    border-radius: 4px;
    cursor: pointer;
}

.artikeldetail_slider_container {
    position: relative;
    width: 100%;
    height: 300px;
    padding-left: 20px;
    border: 1px solid #333;
    border-radius: 4px;
}

.artikeldetail_slider_container > img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    border-radius: 4px;
    cursor: pointer;
}

.artikeldetail_select_xs div {
    padding-bottom: 10px;
}

.artikeldetail_select_xs select {
    color: white;
    background-color: var(--weiss);
}

.artikeldetail_option .input-group > .input-group-addon {
    height: 34px;
    background: transparent;
}

.artikeldetail_aktionspreis_altpreis {
    color: #333;
    font-size: 18px;
    font-weight: 300;
    text-decoration: line-through;
}

.artikeldetail_preis p {
    color: #333;
    font-size: 22px;
    font-weight: 300;
}

div.artikeldetail_preisbemerkung {
    text-align: right;
}

div.artikeldetail_preisbemerkung > p,
div.artikeldetail_preisbemerkung > p > a {
    font-size: 10px;
}

div.artikeldetail_button {
    margin-top: 20px;
}

div.artikeldetail_button > div {
    padding-bottom: 10px;
}

div.artikeldetail_button > div > i {
    color: var(--weiss);
    font-size: 34px;
    cursor: pointer;
}

.artikeldetail_tabset > .nav-tabs {
    border: none;
}

.artikeldetail_tabset > .nav-tabs > li.active > a {
    color: var(--weiss);
    font-size: 14px;
    border: none;
}

.artikeldetail_tabset > .nav-tabs > li > a {
    color: #333;
    font-size: 14px;
    border: none;
}

.artikeldetail_tabset > .nav-tabs > li > a:hover {
    color: var(--weiss);
    background-color: transparent;
}

.artikeldetail_tabset > .tab-content {
    padding: 10px;
    border: 1px solid var(--weiss);
    border-radius: 0 5px 5px 5px;
}

.artikeldetail_tab {
    border: 1px solid var(--weiss);
    border-radius: 5px 5px 0 0;
}

div.artikeldetail_artikelanhang > div > a {
    color: #333;
    font-size: 14px;
    font-weight: 300;
}

.artikeldetail_bild_container {
    position: relative;
    width: 100%;
    height: 100px;
    border: 1px solid #333;
    border-radius: 4px;
}

.artikeldetail_bild_container > img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    border-radius: 4px;
    cursor: pointer;
}

.artikeldetail_zubehoer_container {
    margin-top: 20px;
}

.artikeldetail_zubehoer_container > .carousel > .carousel-control {
    width: 0;
}

div.artikeldetail_slider > .carousel-control {
    width: 10%;
    margin-top: 9%;
    color: var(--weiss);
    font-size: 30px;
    background-image: none;
    text-shadow: none;
    opacity: 1;
}

.artikeldetail_alternativartikel_tabelle .artikeldetail_alternativartikel_bildspalte {
    width: 20%;
}

.artikeldetail_alternativartikel_tabelle .artikeldetail_alternativartikel_infospalte {
    width: 50%;
    text-align: left;
}

.artikeldetail_alternativartikel_tabelle .artikeldetail_alternativartikel_preisspalte {
    width: 30%;
    padding-top: 30px;
}

.artikeldetail_alternativartikel_tabelle .artikeldetail_alternativartikel_preisspalte > .artikeldetail_alternativartikel_preis > p {
    text-align: right;
    font-weight: 300;
    font-size: 18px;
}

.artikeldetail_variantenauswahl > div {
    margin-bottom: 10px;
}

.artikeldetail_variantenauswahl .input-group-addon {
    min-width: 70px;
}

.mengumrechnung > tbody > tr:hover {
    opacity: .7;
}

/*  ####################################### */
.detail__body {
    display: flex;
    align-items: flex-start;
}

.detail-wrapp {
    border: 1px solid var(--grau-20);
    border-radius: 6px;
}
.artikeldetail_hauptbild_container {
    height: 330px;
    border-bottom: 1px solid var(--grau-20);
}
.artikeldetail_alternativbilder_container {
    height: 250px;
}
.detail__titel {
    display: flex;
    margin-bottom: 10px;
    margin-top: 10px;
}

.titel__bezeichnung {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.titel__bestellnummer {
    display: flex;
    justify-content: flex-end;
}

.titel__bezeichnung h1 {
    margin: 0;
}
.detail__body-rechts {
    margin-left: 15px;
}
/*.detail__body-links {
    margin-right: 15px;
}*/

.detail__body-rechts p span {
    font-weight: normal;
    font-size: 24px;
}
.detail__body-rechts p span.ihr-preis {
    font-weight: bold;
    font-size: 32px;
    color: var(--blau);
}
.rechts__button {
    background-color: var(--blau-7);
    padding: 15px;
    border-radius: 0 0 6px 6px;
}
.rechts__button button {
    display: flex;
    align-items: center;
    justify-content: center;
}
.rechts__button-mz {
    display: flex;
    justify-content: space-between;
}
.rechts__button-wk button {
    padding-top: 14px;
    padding-bottom: 14px;
}
.button-sofort {
    margin-left: 15px;
}
.button-mz {
    margin-right: 15px;
}
.button-mz,
.button-sofort {
    flex-grow: 1;
}
.mengen__mengum {
    display: flex;
    align-items: center;
    width: 100px;
}
.mengen__mengum input {
    width: 100px;
    height: 42px;
    text-align: center;
}
.mengen__mengum button {
    padding: 9px 15px;
}
.beschreibung__titel {
    background-color: var(--gelb);
    padding: 15px;
    border-right: 2px solid var(--weiss);
}
.beschreibung__titel div {
    padding-top: 9px;
    padding-bottom: 9px;
}
.hidden-xs.hidden-sm .beschreibung__titel > div:first-child,
.hidden-xs.hidden-sm .beschreibung__info > div:first-child {
    border-right: 2px solid var(--weiss);
}
.beschreibung__titel p {
    color: var(--weiss);
    font-size: 18px;
    font-weight: 500;
}
.beschreibung__info {
    display: flex;
    background-color: var(--gelb-5);
    min-height: 100px;
    border-radius: 0 0 6px 6px;
}
.body-rechts__ihr-preis p {
    font-weight: bold;
    font-size: 32px;
    color: var(--blau);
}
/*.info {
    border-left: 2px solid var(--weiss);
}*/
.info .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.info__item {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 2px solid var(--weiss);
}
.info__item:last-child {
    border-bottom: none;
}
.info__item p,
.info__item p span {
    font-size: 13px;
}
.info__item .item__titel {
    padding-left: 0;
}
.info__item .item__wert {
    padding-right: 0;
}

.links__beschreibung {
    background-color: var(--gelb-5);
}
.links__beschreibung .beschreibung__titel {
    padding: 15px;
}


/*
*
* Extra Small (XS)
*/

@media (max-width: 767px) {
    .detail__titel {
        flex-direction: column;
    }

    .titel__bezeichnung {
        flex-direction: column;
        align-items: flex-start;
    }

    .titel__bestellnummer {
        display: flex;
        justify-content: flex-start;
    }
    .artikeldetail_hauptbild_container,
    .artikeldetail_alternativbilder_container {
        height: 180px;
    }
    .detail__body-rechts {
        margin-left: 0;
    }
    .detail__body-links {
        margin-right: 0;
    }
    .mengen__mengum .dropdown-menu span {
        font-size: 12px;
        font-weight: normal;
    }
    .dropdown-menu-right {
        right: -126px;
        left: auto;
    }
}


/*
*
* Small (SM)
*/

@media (min-width: 768px) and (max-width: 991px) {
    .rechts__button-mz {
        flex-direction: column;
    }
    .button-mz {
        margin-bottom: 15px;
    }
    .button-sofort {
        margin-left: 0;
    }
    .button-mz {
        margin-right: 0;
    }
    .artikeldetail_hauptbild_container,
    .artikeldetail_alternativbilder_container {
        height: 190px;
    }
}


/*
*
* Medium (MD)
*/

@media (min-width: 992px) and (max-width: 1199px) {

}


/*
*
* Large (LG)
*/

@media (min-width: 1200px) {

}