

/*
 *
 * Header
 */

.headimg {
    padding-top: 35px;
    padding-bottom: 25px;
}

.headimg img {
    max-width: 100%;
}
.head {
    padding: 10px 15px 0 15px;
}
.head__logo {
    padding-bottom: 30px;
}
.head .head__logo-suchen .input-group-addon {
    font-size: 30px;
    padding: 2px 12px;
    color: var(--grau);
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 4px;
}
.head .head__logo-suchen .form-control {
    display: block;
    width: 100%;
    height: 40px;
    padding: 8px 12px;
    font-size: 16px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 2px solid var(--blau-7);
    border-radius: 6px;
    box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.25);
}
.head__menu {
    background-color: var(--gelb);
    border-bottom: 2px solid var(--weiss);
}
.head__menu ul {
    display: flex;
}
.head__menu ul li {
    background-color: var(--weiss);
}
.head__menu ul li a {
    background-color: var(--gelb);
    border-left: 2px solid var(--weiss);
    border-radius: 6px 0 0 0;
}
.head__menu ul li a:hover {
    background-color: var(--blau);
}
.head__menu ul li:last-child {
    margin-left: auto;
    border-right: 2px solid var(--weiss);
}
/*.head__menu ul li:nth-last-child(2) {
    margin-left: auto;
    border-right: 2px solid var(--weiss);
}*/

.head__img {
    position: relative;
    background-image: url("../../template/images/header_bild_bar.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 27vw;
}
.head__img img.img-bar_finder {
    position: absolute;
    top: 28%;
    right: 22%;
    height: 18vw;
}
.head__img img.img-produced {
    position: absolute;
    bottom: 20%;
    left: 3%;
    height: 10vw;
    z-index: 10;
}
.head__menu-main {
    background-color: var(--blau);
    border-top: 2px solid var(--weiss);
}

.head__menu-main li {
    background-color: var(--weiss);
}

.head__menu-main li a {
    background-color: var(--blau);
}
.head__menu-main ul.dropdown-menu li a span {
    color: var(--blau);
    font-size: 16px;
}
.head__menu-main ul.dropdown-menu li a:hover span {
    font-weight: 500;
}
.head__menu-main ul.dropdown-menu li a {
    background-color: var(--blau-7);
    color: var(--blau);
    padding: 10px 15px;
}
.head__menu-main li a i {
    font-size: 17px;
}

.head__menu-main ul.nav.navbar-nav {
    /*width: 100%;*/
    display: flex;
    justify-content: flex-end;
}

.head__menu-main ul.nav.navbar-nav li:first-child {
    margin-right: auto;
}

.head__menu-li {
    background-color: var(--weiss);
}

.head__menu-li a  {
    background-color: var(--blau);
    color: var(--weiss);
    border-left: 2px solid var(--weiss);
    border-radius: 6px 0 0 0;
    text-transform: uppercase;
}

.navbar-toggle {
    position: relative;
    padding: 9px 10px;
    margin-top: 8px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.head__menu > div.container:before,
.head__menu > div.container:after {
    content: none;
}

.head__menu-icon a i {
    font-size: 26px;
    color: var(--weiss);
}
.nav-stacked>li+li {
    margin-top: 0;
}
.head__logo-suchen.es-suchen input.form-control {
    box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.25);
}

/*
*
* Extra Small (XS)
*/

@media (max-width: 767px) {
    .head__menu-main.navbar {
        margin-bottom: 30px;
    }
    .head__menu-li a  {
        text-transform: none;
    }
    .navbar {
        min-height: 30px;
        margin-bottom: 0;
    }
    .navbar-nav {
        margin: 0;
    }
    .head__logo {
        padding: 15px 0;
    }
    .xxx--page-header--img {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .sm-suchen {
        box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.25);
        height: 40px;
    }
    .sm-suchen i {
        font-size: 25px;
    }
    .navbar-collapse {
        padding-right: 0;
        padding-left: 0;
    }
    .navbar-collapse ul li a {
        background-color: var(--blau);
        border-bottom: 1px solid var(--weiss);
    }
    .navbar-collapse ul li:hover a {
        color: var(--gelb);
    }
}


/*
*
* Small (SM)
*/

@media (min-width: 768px) and (max-width: 991px) {

}


/*
*
* Medium (MD)
*/

@media (min-width: 992px) and (max-width: 1199px) {

}


/*
*
* Large (LG)
*/

@media (min-width: 1200px) {

}
